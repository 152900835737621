.group-2 {
    @include margin(35px null);
    width: 100%;

    &__title {
        @include padding(0 20px 0 0);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &,
        &:focus {
            outline: none;
        }

        &--highlight {
            color: $primary;

            .group-2__arrow {
                border-color: $primary;
            }
        }
    }

    &__title-text {
        font-size: 28px;
    }

    &__arrow {
        @include padding(3px);
        @include size(28px);
        border-radius: 50%;
        border: 1px solid $gray-7;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
        transition: transform 0.1s linear;

        .collapsed & {
            transform: rotate(0);
        }
    }

    &__content {
        @include padding(20px null null);
    }

    .collapsing {
        @include margin(null -20px);
        @include padding(null 20px);
    }

    .news {
        padding-top: 0;
    }
}
