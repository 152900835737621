.avatar {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .3);

    &__container {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
    }

    &__image {
        @include size(100%);
        object-fit: contain;
    }

    &--xxs-fixed {
        @include media-breakpoint-down(xxs) {
            width: 100px;
            padding-bottom: 100px;
        }
    }
}
