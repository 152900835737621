.alert {
    background-color: $white;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 8px 0 rgba($black, 0.4);

    &__text {
        @include padding(5px 20px);
        min-height: 40px;
        flex: 1;
        font-family: $ff-tertiary;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &,
    &__button {
        border-radius: 7px;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(xs) {
            min-width: auto;
        }
    }

    &-dark {
        background-color: $gray-14;
    }

    &--secondary {
        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }
    }
}
