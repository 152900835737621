.group-details {
    display: flex;
    font-family: $ff-secondary;

    @include media-breakpoint-down(xxs) {
        @include margin(15px null);
        @include padding(null null 15px);
        border-bottom: 1px solid $gray-10;
        flex-direction: column;
        align-items: center;
    }

    &__logo,
    &__data {
        @include media-breakpoint-down(xxs) {
            text-align: center;
        }
    }

    &__logo {
        @include media-breakpoint-up(xs) {
            width: 80px;
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-up(sm) {
            width: 100px;
        }
    }

    &__image {
        max-width: 100%;
    }

    &__data {
        @include media-breakpoint-up(xs) {
            @include padding(null 15px null 20px);
            flex: 1;
            height: 93px;
            overflow: hidden;
        }
    }

    &__title {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;

        a {
            color: inherit;
        }
    }

    &__description {
        font-size: 12px;
        line-height: 1.55;
    }

    &__member-label {
        @include media-breakpoint-down(xxs) {
            margin-top: 10px;
        }
    }

    & + & {
        margin-top: 12px;
    }
}
