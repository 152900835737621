.tab-block-1 {
    @include border-top-radius(7px);

    &__body {
        @include padding(20px 25px);
        position: relative;
        overflow: hidden;
    }

    &__icon {
        @include position(absolute, 10px 25px null null);
        max-width: 50px;

        &--up {
            top: 5px;
        }
    }

    .heading-bg {
        width: 210px;
        padding-right: 0;
        font-size: 23px;
        margin-left: -30px;
    }

    .tab-content {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        background-color: $white;
        position: relative;
        z-index: 1;
    }

    .nav-tabs {
        flex-direction: row;
    }

    .nav-item {
        flex: 1;
    }

    .nav-link {
        @include border-top-radius(7px);
        @include padding(0);
        height: 36px;
        opacity: 1;
        color: $white;
        background-color: $primary;
        font-family: $ff-tertiary;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;

        &.active {
            color: $primary;
            background-color: $white;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
        }
    }
}
