.btn {
    font-family: $ff-tertiary;
    font-weight: 600;
    font-size: .75rem;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    line-height: 1rem;
    min-width: 70%;
    position: relative;

    @include media-breakpoint-up(sm) {
        min-width: 200px;
    }

    &-sm {
        font-size: .625rem;

        @include media-breakpoint-up(sm) {
            min-width: 120px;
        }
    }

    &-lg {
        height: 40px;
    }

    &-link {
        text-transform: none;
        letter-spacing: 0;
        border: none;

        &.btn-primary {
            color: $primary;
        }

        &.btn-secondary {
            color: $secondary;
        }

        i + span,
        span + i {
            margin-left: 5px;
        }
    }

    &__icon {
        @include position(absolute, 0.33em null null 1em);
        font-size: 1.5em;

        .btn-sm & {
            top: 0.25em;
        }

        .btn-lg & {
            top: 0.4em;
        }
    }

    .input-group-append &,
    .input-group-prepend & {
        min-width: 0;
    }

    &--reset-min-width {
        @include media-breakpoint-up(xs) {
            min-width: 0;
        }
    }

    &--reset {
        @include padding(null 0);
        min-width: 0;
    }

    &--max-width {
        max-width: 200px;
        width: 100%;
        min-width: 0;

        &-xs {
            min-width: 0;

            @include media-breakpoint-down(xxs) {
                display: block;
                width: auto;
            }

            @include media-breakpoint-up(xs) {
                max-width: 200px;
            }
        }
    }

    &--fb {
        background-color: $blue4;

        .btn__icon {
            @include margin(-12px 20px null null);
            position: relative;
            left: auto;
            top: 7px;
            font-size: 28px;
        }

        &,
        &:hover {
            color: $white;
        }

        &:focus,
        &.focus {
            outline: 0;
            box-shadow: 0 0 0 .2rem rgba($blue4, 0.25);
        }
    }

    .fas {
        font-size: 1rem;
        position: relative;
        top: 1px;
    }

    &__text + *,
    > * + .btn__text {
        margin-left: 0.625rem;
    }
}
