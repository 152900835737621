.news-inline {
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
    }

    &__image {
        @include media-breakpoint-up(sm) {
            flex-basis: 30%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 35%;
        }

        @include media-breakpoint-up(xl) {
            flex-basis: 25%;
        }
    }

    &__content {
        @include media-breakpoint-up(sm) {
            flex-basis: 70%;
            padding-left: 20px;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 65%;
        }

        @include media-breakpoint-up(xl) {
            flex-basis: 75%;
        }
    }

    &__title {
        @include margin(5px null null null);
        font-family: $ff-secondary;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-5;
        display: inline-block;

        @include media-breakpoint-up(sm) {
            @include margin(0 null null null);
        }

        &:hover {
            color: $gray-5;
            opacity: .7;
        }
    }

    &__description {
        @include margin(5px null null null);
        font-family: $ff-tertiary;
        font-size: 12px;
        color: $gray-5;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .image-block {
        @include media-breakpoint-up(sm) {
            flex: 1;
        }
    }

    .btn {
        @include margin(10px null null null);

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    & + & {
        @include margin(20px null null null);

        @include media-breakpoint-up(sm) {
            @include margin(40px null null null);
        }
    }

    &--secondary {
        @include padding(15px 20px);
        box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);

        @include media-breakpoint-down(xs) {
            text-align: center;
        }

        .news-inline {
            &__image {
                @include media-breakpoint-up(sm) {
                    flex-basis: 120px;
                }
            }

            &__title {
                @include media-breakpoint-down(xs) {
                    margin-top: 15px;
                }
            }

            &__description {
                display: block;
            }

            &__content {
                @include media-breakpoint-up(sm) {
                    padding-left: 15px;
                    flex: 1;
                }
            }
        }

        .image-block {
            @include margin(null auto);
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
            width: 120px;
        }

        .btn {
            @include padding(null 15px);
            display: inline-block;
            margin-top: 10px;
            min-width: auto;
        }
    }

    &--tertiary {
        @include media-breakpoint-down(xs) {
            text-align: center;
        }

        & + & {
            margin-top: 17px;
        }

        .news-inline {
            &__image {
                @include media-breakpoint-up(sm) {
                    flex-basis: 65px;
                }
            }

            &__title {
                font-size: 12px;

                @include media-breakpoint-down(xs) {
                    margin-top: 10px;
                }
            }

            &__description {
                font-size: 11px;
                margin-top: 0;
                display: block;
            }

            &__content {
                @include media-breakpoint-up(sm) {
                    padding-left: 15px;
                    flex: 1;
                }
            }
        }

        .image-block {
            @include margin(null auto);
            border-radius: 5px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
            width: 65px;

            &__image {
                width: 50px;
                height: auto;
            }

            &__link {
                display: none;
            }
        }

        .btn {
            display: none;
        }
    }
}
