.letters-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;

    &__link {
        font-family: $ff-secondary;
        font-weight: 700;
        font-size: 14px;
        color: $white;
        display: inline-block;
        text-decoration: none;
        padding: 0 6px;
        border-bottom: 3px solid transparent;

        @include media-breakpoint-up(sm) {
            padding: 0 10px;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 6px;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 7px;
        }

        &.is-active,
        &:hover {
            opacity: 1;
            color: $white;
            border-bottom-color: $white;
        }

        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }
    }
}
