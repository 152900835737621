.popular-list {
    font-family: $ff-secondary;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .sub-menu + & {
        margin-top: 7px;
    }

    &__main,
    &__link {
        @include margin(.2em null);
    }

    &__main {
        color: $primary;

        &:after {
            @include margin(null 1em null .5em);
            font-family: 'Font Awesome 5 Free';
            font-size: .9em;
            font-weight: 900;
            content: '\f054'
        }
    }

    &__link {
        position: relative;
        padding-right: 1.2em;

        &,
        &:hover {
            color: $gray-10;
        }

        &:not(:last-child) {
            &:after {
                @include position(absolute, -.25em .3em null null);
                color: $primary;
                content: '|';
                font-weight: 300;
                font-size: 1.4em;
            }
        }
    }
}
