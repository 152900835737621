.video-control {
    @include padding(5px .3125em);
    @include position(absolute, null 2px 2px 2px);
    font-size: 12px;

    &__progress-holder,
    &__progress {
        height: .5em;
        min-height: .5em;
    }

    &__progress-holder {
        @include position(absolute, null 0 0 0);
        background-color: $black;
    }

    &__progress {
        background-color: $green;
    }

    &__buttons-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
    }

    &__button {
        @include margin(.3125em);
        @include size(2em);
        border-radius: .5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $black;
        color: $white;

        &:hover {
            opacity: 1;
            color: $blue2;
        }

        i {
            position: relative;
            top: 1px;
        }
    }
}
