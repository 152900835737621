.sub-menu {
    @include margin(null auto);
    @include padding(null 30px);
    max-width: 750px;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    @include media-breakpoint-down(md) {
        display: none;
    }

    &__title {
        flex: 0;
        padding-right: 30px;
    }

    &__title-link {
        color: $primary;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__links {
        flex: 1;
        overflow: hidden;
        position: static;
    }

    &__slider {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &:not(.slick-initialized) {
            & + .slider__arrows {
                display: none;
            }
        }
    }

    &__item {
        white-space: nowrap;
        padding: 0 10px;

        &.is-active {
            text-decoration: underline;
        }
    }
}
