.nav-group {
    .sidebar & {
        @include margin(null null null 40px);
        width: 60%;
        min-width: 250px;
        max-width: 400px;
    }

    &__head {
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 40px;

        &--full {
            .nav-group__button {
                @include position(absolute, 0 0 0 0);
                width: 100%;
            }

            &:hover {
                .nav-group__title {
                    opacity: .7;
                }
            }
        }
    }

    &__title {
        flex: 1;
    }

    &__button {
        @include padding(null 12px null null);
        @include size(40px);
        text-align: right;

        &.collapsed {
            i {
                transform: rotate(0);
            }
        }

        i {
            font-size: 14px;
            transform: rotate(90deg);
            transition: transform $transition-duration $transition-timing-function;
        }
    }

    &__title,
    &__link {
        font-family: $ff-tertiary;
        color: $black;
        text-transform: uppercase;

        &:hover {
            color: $black;
            opacity: .7;
        }

        &.is-active {
            text-decoration: underline;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 700;

        .sidebar & {
            @include padding(8px null);
        }
    }

    &__content {
        @include padding(null null null 35px);
        list-style-type: none;
    }

    &__link {
        font-size: 12px;
        font-weight: 700;
        display: inline-block;

        .sidebar & {
            @include padding(11px null);
        }
    }

    & + & {
        @include margin(20px null null);

        .sidebar & {
            @include margin(0 null null);
        }
    }
}
