.article {
    font-family: $ff-tertiary;

    &__title {
        @include margin(15px null 0);
        font-size: 32px;
        font-family: $ff-primary;
        font-weight: 400;
    }

    &__sponsored {
        @include margin(20px null null null);
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 700;
        color: $gray-8;
        text-transform: uppercase;
    }

    img {
        @include article-content-img;
        @include margin(5px);

        @include media-breakpoint-down(xs) {
            @include margin(20px 0);
        }
    }

    &__header {
		@include margin(null null 20px null);

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
        }

        img {
			@include margin(0 auto);
            order: 1;
			display: block;

            @include media-breakpoint-up(md) {
                @include margin(15px null null null);
            }
        }
    }

    &__info {
        order: 2;
    }

    h3 {
        @include margin(20px null 0);
        font-size: 20px;
        font-weight: 700;

        & + p {
            @include margin(5px null null null);
        }
    }

    p {
        @include margin(20px null);
    }

    .breadcrumb {
        display: flex;

        @include media-breakpoint-up(lg) {
            @include margin(0 null);
        }
    }
}
