.article-expert-footer {
    @include margin(30px auto);
    @include padding(20px);
    max-width: 280px;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .3);

    @include media-breakpoint-up(sm) {
        @include padding(10px);
        max-width: none;
    }

    &,
    &__content {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    &__content {
        @include padding(null null 20px null);

        @include media-breakpoint-up(sm) {
            @include padding(null 20px 0 null);
            flex: 1;
        }
    }

    &__image-block {
        display: flex;
        min-width: 230px;
        flex-basis: 230px;

        @include media-breakpoint-up(sm) {
            min-width: 120px;
            flex-basis: 120px;
        }

        @include media-breakpoint-up(md) {
            min-width: 150px;
            flex-basis: 150px;
        }

        @include media-breakpoint-only(lg) {
            min-width: 100px;
            flex-basis: 100px;
        }
    }

    &__data {
        @include margin(15px null null null);
        text-align: center;

        @include media-breakpoint-up(sm) {
            @include margin(0 null null null);
            @include padding(null null null 10px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            @include padding(null null null 15px);
        }
    }

    &__name {
        @include margin(null null 10px null);
        font-size: 26px;
        color: $gray-5;
        line-height: 1;

        @include media-breakpoint-up(xl) {
            font-size: 28px;
        }
    }

    &__title,
    &__www {
        font-size: 14px;
        color: $gray-5;
        font-family: $ff-tertiary;
    }

    &__www {
        text-decoration: none;

        &:hover {
            color: $gray-5;
            text-decoration: underline;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-up(sm) {
            justify-content: flex-end;
        }
    }

    &__badge {
        text-align: center;

        @include media-breakpoint-up(sm) {
            @include margin(null 10px null null);
            text-align: right;
            order: 1;
        }

        @include media-breakpoint-down(xs) {
            @include margin(10px null null null);
        }
    }

    .btn {
        font-family: $ff-secondary;
        font-size: 10px;

        @include media-breakpoint-up(sm) {
            @include margin(10px null null null);
            order: 2;
            min-width: 0;
            max-width: 100%;
        }
    }
}
