.head {
    &__title {
        font-size: 36px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-family: $ff-tertiary;
        font-size: 12px;

        @include media-breakpoint-up(sm) {
            @include padding(0 null 10px);
            flex-direction: row;
            justify-content: space-between;
            border-top: 1px solid $gray-7;
            border-bottom: 1px solid $gray-7;
        }

        strong {
            margin-right: 5px;
        }
    }

    &__cover {
        @include margin(null auto);

        @include media-breakpoint-up(lg) {
            max-width: 690px;
			&_vertical {
				max-width: 390px;
			}
        }
    }
}
