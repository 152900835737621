.slider-1 {
    .slider__wrapper {
        overflow: hidden;
    }

    .slick-list {
        margin-left: -15px;
    }

    .news {
        @include margin(0 null null null);
        @include padding(0 null null 15px);

        &__image-block {
            @include margin(null 0);
        }

        &__title,
        &__link {
            font-size: 14px;
        }
    }

    .slider__arrows {
        top: 43px;

        @include media-breakpoint-up(xs) {
            top: 32%;
        }

        @include media-breakpoint-up(sm) {
            top: 35%;
        }
    }
}
