.browse-things-block {
    &__content {
        @include margin(20px null null null);
        font-family: $ff-tertiary;
        font-size: 14px;
    }

    &__form {
        @include margin(null -5px);
        display: flex;
        flex-wrap: wrap;
        //display: grid;
        //grid-gap: 10px;
        //
        //@include media-breakpoint-up(sm) {
        //    grid-gap: 15px;
        //    grid-template-rows: repeat(2, 1fr);
        //    grid-template-columns: repeat(2, 1fr);
        //}
        //
        //@include media-breakpoint-only(md) {
        //    grid-gap: 10px;
        //    grid-template-columns: repeat(4, 1fr);
        //    grid-template-rows: 1fr;
        //}
        //
        //@include media-breakpoint-only(xl) {
        //    grid-gap: 10px;
        //    grid-template-columns: repeat(4, 1fr);
        //    grid-template-rows: 1fr;
        //}

        .form-group {
            @include margin(10px null 0);
            @include padding(null 5px);
            flex: 0 0 100%;

            @include media-breakpoint-up(sm) {
                flex: 0 0 50%;
            }

            @include media-breakpoint-up(md) {
                flex: 0 0 25%;
            }

            @include media-breakpoint-only(lg) {
                flex: 0 0 50%;
            }
        }

        .form-control {
            width: 100%;
        }

        .btn {
            width: 100%;
            min-width: 0;
        }

        &--secondary {
            .form-group {
                @include media-breakpoint-up(sm) {
                    flex: 0 0 39%;
                }

                &:last-child {
                    @include media-breakpoint-up(sm) {
                        flex: 0 0 22%;
                    }
                }
            }
        }
    }

    .heading-bg {
        font-size: 28px;
    }
}
