.name-block {
    @include padding(null null 20px);
    @include size(100%);
    position: relative;
    display: flex;
    flex-direction: column;

    &__name {
        @include margin(null -10px 5px null);
        font-size: 32px;
        font-family: $font-family-base;
        color: $primary;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__content {
        flex: 1;
        overflow: hidden;
    }

    &__description {
        @include margin(null null 10px null);
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 600;
        color: $gray-10;
    }

    &--sm {
        @include padding(10px 15px);
        height: 116px;
        margin-top: 20px;
        background-color: $gray-12;

        &:before {
            @include position(absolute, 0 null null 0);
            @include size(100%);
            content: '';
            box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);
            z-index: -2;
        }

        &:after {
            @include position(absolute, null null -10px 0);
            @include size(100%, 10px);
            content: '';
            background: linear-gradient(to right,
                    rgba(255,255,255,0) 0%,
                    rgba(255,255,255,0.8) 8%,
                    rgba(255,255,255,1) 16%,
                    rgba(255,255,255,1) 20%,
                    rgba(255,255,255,1) 50%,
                    rgba(255,255,255,1) 80%,
                    rgba(255,255,255,1) 84%,
                    rgba(255,255,255,0.8) 92%,
                    rgba(255,255,255,0) 100%);
            z-index: -1;
        }

        .name-block__description {
            margin-bottom: 0;
        }
    }

    .btn-fav {
        @include position(absolute, null 0 0 null);
    }
}
