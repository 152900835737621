.social-list {
    @include margin(null -10px);
    display: flex;
    flex-direction: row;
    align-items: center;

    &__link {
        @include padding(5px 10px);
        display: block;
        color: $gray-2;
        line-height: 1;

        &:hover {
            color: $gray-5;
        }
    }

    &--lg {
        font-size: 25px;

        .social-list__link {
            color: $gray-5;

            &:hover {
                opacity: .7;
            }
        }
    }
}
