.recipes-info {
    @include media-breakpoint-down(xs) {
        @include margin(null -15px);
    }

    @include media-breakpoint-up(sm) {
        padding-top: 36px;
        position: relative;
    }

    &__block {
        font-family: $ff-tertiary;
        font-size: 12px;

        & + & {
            margin-top: 15px;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }

        &.is-active {
            @include media-breakpoint-up(sm) {
                border: 1px solid $gray-14;

                .recipes-info__body {
                    display: block;
                }

                .recipes-info__icon {
                    transform: rotate(90deg);
                }
            }

            .recipes-info__title {
                @include media-breakpoint-up(sm) {
                    background-color: $gray-16;
                }
            }
        }

        &.is-expanded {
            @include media-breakpoint-down(xs) {
                .recipes-info__body {
                    display: block;
                }

                .recipes-info__icon {
                    transform: rotate(90deg);
                }
            }
        }

        @include media-breakpoint-up(sm) {
            &:first-child {
                .recipes-info__title {
                    border-left: 1px solid $gray-14;
                }
            }

            &:nth-child(2) {
                .recipes-info__title {
                    left: 25%;
                }
            }

            &:nth-child(3) {
                .recipes-info__title {
                    left: 50%;
                }
            }

            &:nth-child(4) {
                .recipes-info__title {
                    left: 75%;
                }
            }
        }
    }

    &__title {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-16;
        border-top: 1px solid $gray-14;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $gray-14;
        }

        @include media-breakpoint-up(sm) {
            @include position(absolute, 0 null null 0);
            border-right: 1px solid $gray-14;
            width: 25%;
            height: 36px;
            background-color: $white;
        }
    }

    &__text {
        width: 250px;
    }

    &__icon {
        font-size: 10px;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__body {
        @include padding(15px 15px 10px);
        display: none;

        @include media-breakpoint-down(xs) {
            @include padding(null 15px);
        }

        @include media-breakpoint-up(sm) {
            @include padding(20px 20px 15px);
        }

        > * {
            & + h3,
            & + h4 {
                margin-top: 20px;
            }
        }

        h3 {
            font-size: 20px;
        }

        h4 {
            font-size: 18px;
        }
    }
}
