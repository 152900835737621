.swipe-list {
    @include media-breakpoint-up(sm) {
        overflow: hidden;
    }

    > .row {
        @include padding(null ($swipe-row-space * 4) null 8px);

        @include media-breakpoint-up(xs) {
            padding-right: $swipe-row-space * 5;
        }

        @include media-breakpoint-up(md) {
            @include padding(null 0);
        }

        @include media-breakpoint-down(sm) {
            @include swipe-row;
        }

        > .col,
        > [class*='col-'] {
            @include padding(null 7px);
        }
    }
}
