.dropdown {
    z-index: 9;

    &-toggle,
    &-menu {
        width: 200px;
    }

    &-menu {
        padding-top: 0;
        background-color: $white;
        border: 0;
    }

    &__items {
        max-height: 190px;
        overflow: auto;
    }

    &-item {
        @include padding(7px 15px);
        font-family: $ff-secondary;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
    }

    &__search {
        &-input {
            @include padding(5px 15px);
            width: 100%;
            border: 0;
            background-color: transparent;
            font-family: $ff-secondary;
            font-weight: 700;
            font-size: 10px;
            text-transform: uppercase;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    &.show {
        .dropdown-toggle {
            @include border-bottom-radius(0);
        }

        .dropdown-menu {
            @include border-top-radius(0);
            margin-top: 0;
        }
    }
}
