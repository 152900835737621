.category-topic-list {
    @include media-breakpoint-down(md) {
        @include padding(0 null);
        overflow: hidden;
    }

    @include media-breakpoint-down(xs) {
        @include margin(null -15px null null);
    }

    .slick-list {
        @include margin(null null null -15px);
        width: calc(100% + 15px);

        @include media-breakpoint-down(md) {
            width: 85%;
            overflow: visible;
        }
    }

    .slick-slide {
        @include padding(null null null 15px);
    }
}
