.slider-7 {
    @include margin(null -10px);

    &__item {
        padding-bottom: 10px;
    }

    &__item-title {
        @include padding(7px 20px);
        margin-top: 3px;
        font-family: $ff-secondary;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    }

    .slick-list {
        @include padding(null 10px);
    }

    .dots-block {
        @include margin(0 null 20px);

        @include media-breakpoint-up(sm) {
            @include position(absolute, 360px 0 null 0);
        }

        @include media-breakpoint-up(md) {
            top: 270px;
        }

        @include media-breakpoint-up(lg) {
            top: 325px;
        }

        @include media-breakpoint-up(xl) {
            top: 390px;
        }
    }

    .dots-block__item {
        @include size(11px);
        background-color: $white;
        box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
    }

    .slider__arrows {
        .slider-arrow {
            &--left {
                left: -6px;
            }

            &--right {
                right: -6px;
            }
        }

        @include media-breakpoint-down(xs) {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            top: 174px;
        }

        @include media-breakpoint-up(md) {
            top: 129px;
        }

        @include media-breakpoint-up(lg) {
            top: 158px;
        }

        @include media-breakpoint-up(xl) {
            top: 196px;
        }
    }
}
