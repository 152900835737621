.recipes-spinner {
    text-align: center;

    &__unit {
        @include padding(null 7.5px);
    }

    &__button {
        opacity: 1;
        min-width: 140px;
        height: 30px;
        transition: opacity $transition-duration $transition-timing-function;

        @include media-breakpoint-down(sm) {
            margin-top: 10px;
        }

        &.is-active {
            pointer-events: none;
            opacity: .5;
        }
    }

    .heading-bg {
        padding: 0;
        justify-content: center;

        &__text {
            font-family: $ff-secondary;
            font-size: 20px;
            position: relative;
            top: -1px;
        }
    }

    .row {
        flex-wrap: nowrap;
        overflow: hidden;
    }

    .col,
    [class*='col-'] {
        @include padding(null 5px);
        max-width: 100%;
    }

    .slick-slide {
        @include padding(25px null);

        @include media-breakpoint-up(xs) {
            @include padding(15px null);
        }

        &:not(.slick-current) {
            pointer-events: none;

            .image-block {
                &:before {
                    @include position(absolute, 0 null null 0);
                    @include size(100%);
                    background-color: rgba(0, 0, 0, .55);
                    content: '';
                    z-index: 1;
                }
            }
        }

        &.slick-current {
            .image-block {
                border: 4px solid $primary;
                box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.3);
            }
        }
    }

    .slick-current {
        transform: scale(1.2);
        z-index: 2;
        position: relative;
    }
}
