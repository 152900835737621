.questions-block {
    &__list {
        @include margin(null -15px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            @include margin(null -20px);
        }

        &--column {
            margin-right: 0;
            flex-direction: column;

            .questions-block__item {
                @include media-breakpoint-up(sm) {
                    width: 100%;
                }

                &:nth-child(even) {
                    background-color: $gray-13;
                }

                &:nth-child(odd) {
                    background-color: transparent;
                }
            }
        }
    }

    &__item {
        @include padding(30px 15px);

        @include media-breakpoint-down(sm) {
            &:nth-child(even) {
                background-color: $gray-13;
            }
        }

        @include media-breakpoint-up(md) {
            @include padding(45px 20px);

            &:nth-child(4n),
            &:nth-child(4n-1) {
                background-color: $gray-13;
            }
        }

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    .question {
        margin-top: 0;
    }
}
