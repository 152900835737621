.article-slider {
    margin-bottom: 30px;

    &__title {
        @extend .article__title;
        margin-top: 0;
    }

    &__sponsored {
        @extend .article__sponsored;
        padding-right: 50px;
    }

    .article-content {
        margin-bottom: 20px;
    }

    & + .article-ad {
        margin-top: 30px;
    }
}
