@mixin members-list-news-small {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    .news__link {
        font-size: 10px;
    }
}

.members-list {
    margin-top: 20px;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    .news {
        margin-top: 0;
        padding-top: 0;
    }

    .news__image-block {
        @include media-breakpoint-down(xs) {
            @include margin(null 0);
        }
    }

    .news__title {
        margin-top: 5px;
    }

    .news__link {
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 600;
    }

    @include media-breakpoint-only(xs) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-only(md) {
        @include members-list-news-small;
    }

    @include media-breakpoint-only(xl) {
        @include members-list-news-small;
    }

    &--contributors {
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        .news__title {
            text-align: center;
        }

        .news__link {
            font-size: 20px;
        }

        @include media-breakpoint-up(xs) {
            grid-template-columns: repeat(2, 1fr);

            .news__title {
                text-align: left;
            }

            .news__link {
                font-size: 16px;
            }
        }

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(3, 1fr);

            .news__link {
                font-size: 14px;
            }
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
