.more-stories-block {
    @include padding(30px 30px);
    background-color: $light-red2;

    @include media-breakpoint-down(xs) {
        @include margin(null -15px);
    }

    &__title {
        @include margin(0 null);
        font-size: 26px;
        font-weight: 400;
        text-align: center;
    }

    &__content {
        @include margin(20px null null null);
    }

    .tag {
        &--lg {
            margin-top: -50px;
            margin-bottom: 30px;

            @include media-breakpoint-up(xl) {
                margin-bottom: 40px;
            }
        }
    }
}
