.chat-block {
    @include padding(0 20px 10px);
    font-family: $ff-tertiary;
    background-color: $white;
    box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.4);
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__head {
        @include margin(null -20px);
        @include padding(6px 20px);
        color: $white;
        font-weight: 600;
        text-align: center;
        background-color: $secondary;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            position: relative;
            z-index: 1;
        }

        &:before {
            @include position(absolute, -25px null null 30px);
            @include size(0);
            content: '';
            display: block;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 50px solid $secondary;
            transform: rotate(-45deg);
        }
    }

    &__body {
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;

        &--fixed-height {
            overflow: hidden;
            height: 64px;
        }
    }

    & + & {
        margin-top: 10px;
    }
}
