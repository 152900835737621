.group-3 {
    @include margin(null null null -2%);
    @include margin(null null 2% null);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__item {
        @include margin(2% null null 2%);
        width: 18%;
    }

    .image-block,
    .news {
        @include margin(0 null null);
        @include padding(0 null null);
    }

    .news__content {
        @include padding(null null null 10px);
    }

    &__news-group {
        @include size(100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > * {
            &:not(:first-child) {
                @include margin(15px null null null);
            }
        }
    }

    &--3-columns {
        .group-3__item {
            width: 31.3333%;
        }
    }
}
