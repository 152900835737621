.sidebar-button {
    @include padding(2em);
    @include position(absolute, 0 null null 0);
    font-size: $sidebar-button-size;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &__content {
        @include size(1em);
        background-color: $primary;
        color: $primary;
        display: block;
        box-shadow: -$sidebar-button-space 0 -($sidebar-button-space),
                    0 0 -($sidebar-button-space),
                    $sidebar-button-space (-$sidebar-button-space),
                    -($sidebar-button-space) 0,
                    $sidebar-button-space 0,
                    -($sidebar-button-space) $sidebar-button-space,
                    0 $sidebar-button-space,
                    $sidebar-button-space $sidebar-button-space;
    }
}