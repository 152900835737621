.due-date-block {
    margin-top: 40px;
    text-align: center;

    &__result {
        display: inline-block;
        text-align: center;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: self-start;
    }

    &__cell {
        &:last-child {
            margin-left: 20px;
            min-width: 70px;
        }
    }

    .heading-bg {
        text-transform: none;
    }
}
