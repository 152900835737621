.user-block {
    @include size(40px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;

    &__login-button {
        @include size(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        z-index: 1;

        &:hover {
            opacity: 1;
        }
    }

    &__login-button-icon {
        @include size(20px);
    }

    &__container {
        @include padding(45px null null null);
        @include position(absolute, 0 0 null null);
    }

    &__content {
        @include padding(15px);
        display: none;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: $white;

        @include media-breakpoint-up(sm) {
            @include padding(20px);
            top: 50px;
        }
    }

    &__avatar {
        @include size(75px);
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            @include size(100px);
        }

        img {
            @include size(100%);
            object-fit: contain;
        }
    }

    &__content,
    &__avatar {
        border-radius: 8px;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .3);
    }


    &__data {
        @include margin(null null null 10px);
        font-family: $ff-secondary;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-up(sm) {
            @include margin(null null null 15px);
            @include padding(5px null);
        }
    }

    &__name {
        font-size: 14px;
        font-weight: 600;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }
    }

    &__email {
        font-size: 12px;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
    }

    &__button-group {
        display: flex;
        flex-direction: row;

        .btn {
            display: flex;
            min-width: 0;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(xs) {
                @include padding(3px 10px);
                font-size: 10px;
            }

            &:not(:first-child) {
                @include margin(null null null 7px);
            }

            i {
                @include margin(null null null 7px);
            }
        }
    }

    &.is-visible {
        .user-block__login-button {
            border: 2px solid $primary;
        }

        .user-block__content {
            display: flex;
        }
    }
}
