.gradient {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
        @include position(absolute, 0 null 0 null);
        display: none;
        content: '';
        z-index: 1;

        @include media-breakpoint-up(sm) {
            width: 100px;
            display: block;
        }

        @include media-breakpoint-up(lg) {
            width: 150px;
        }
    }

    &:before {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        left: 0;
    }

    &:after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        right: 0;
    }
}
