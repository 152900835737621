.news {
    @include margin(30px null null null);
    @include padding(14px null null null);
    position: relative;

    &__media {
        position: relative;

        &:before {
            @include padding(75% null null null);
            content: '';
            display: block;
        }

        .news__image-block,
        .image-block {
            @include position(absolute, 0 0 0 0);
            @include size(100%);
            z-index: 0;
        }

        .news__image-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__tag {
        position: relative;
        max-width: 100%;
        font-size: 0;
        z-index: 2;

        &--top {
            @include margin(-14px null null);
        }
    }

    &__image-link {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        display: block;
        z-index: 1;
    }

    &__title {
        @include margin(10px null 0 null);
    }

    &__title,
    &__link {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }

        &,
        &:hover {
            color: $gray-5;
        }
    }

    & > .news__image-block,
    & > .news__media {
        @include media-breakpoint-down(xs) {
            @include margin(null -15px);
        }
    }

    &--horizontal {
        @include clearfix;
        @include margin(15px null null null);
        display: flex;

        .news__title {
            @include margin(0 null null null);
        }

        .news__title,
        .news__link {
            font-size: 14px;
            line-height: 1.4;
        }

        .news__image-block {
            flex-basis: 40%;
        }

        .news__content {
            @include padding(null null null 15px);
            flex-basis: 60%;
        }
    }

    &--secondary {
        .news-list &.news {
            margin-top: 20px;
        }

        .news {
            &__content {
                text-align: center;
            }

            &__title,
            &__link {
                font-size: 18px;
            }

            &__description {
                margin-top: 10px;
                font-size: 12px;
            }
        }
    }
}
