.register-fb {
    @include padding(40px 20px 50px);
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);
    font-family: $ff-tertiary;
    text-align: center;

    &__text {
        @include margin(null auto);
        max-width: 320px;
        font-size: 12px;
        font-weight: 600;

        a {
            margin-left: 5px;

            &,
            &:hover {
                color: $blue4;
            }
        }
    }

    .btn--fb {
        margin: auto;
        max-width: 250px;
    }
}
