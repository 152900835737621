@font-face {
    font-family: 'Alice';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts}Alice-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('#{$fonts}Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts}Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('#{$fonts}Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fonts}Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('#{$fonts}Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('#{$fonts}Montserrat-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts}Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('#{$fonts}Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fonts}Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts}MyriadProRegular') format('truetype');
}
