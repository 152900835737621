.article-contributor {
    @include margin(20px null);
    @include padding(null 20px null null);
    display: flex;
    flex-direction: row;
    max-width: 450px;

    &__image-block {
        width: 102px;
    }

    &__data {
        @include padding(null null null 20px);
        font-family: $ff-tertiary;
        font-size: 12px;
        color: $gray-10;
        flex: 1;
    }

    &__name {
        @include margin(-2px null 5px null);
        font-family: $font-family-base;
        font-size: 20px;
        line-height: 1.1;

        &,
        &:hover {
            color: inherit;
        }
    }
}
