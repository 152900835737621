.nav {
    @include margin(10px auto null);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .header & {
        @include margin(15px null null);
    }

    &__link {
        @include padding(5px 25px);
        font-family: $ff-secondary;
        font-size: 14px;

        .header & {
            text-transform: uppercase;
            font-weight: 600;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .footer & {
            @include padding(5px 10px);
            font-family: $ff-primary;
        }

        &.is-active {
            &,
            &:hover {
                span {
                    border-bottom: 2px solid $primary;
                }
            }
        }
    }

    &--secondary {
        .nav__link {
            @include padding(null 12px);
            font-family: $ff-primary;
            font-size: 15px;
            font-weight: 400;
            text-transform: none;
            color: $black;

            &.is-selected {
                color: $primary;
                text-decoration: underline;
            }
        }
    }
}
