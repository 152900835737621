.link {
    &-secondary {
        text-decoration: underline;

        &,
        &:hover {
            color: $gray-3;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &-tertiary {
        text-decoration: none;

        &,
        &:hover {
            color: inherit;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &-light-gray {
        text-decoration: none;

        &,
        &:hover {
            color: $gray-10;
        }
    }

    &-underline {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &-initial {
        &,
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
