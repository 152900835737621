.tv-menu-slider {
    display: flex;
    flex-direction: column;

    &__container {
        position: relative;

        @include media-breakpoint-down(md) {
            min-width: 100%;
            order: 2;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 5px;
        }
    }

    &__item {
        @include media-breakpoint-down(md) {
            @include padding(null 5px);
        }

        &.is-active {
            @include media-breakpoint-down(md) {
                .image-block {
                    &:before {
                        background-color: rgba($black, 0.2);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .image-block__title-link {
                    text-decoration: underline;
                }
            }
        }
    }

    &__search {
        @include margin(20px auto 40px);
        max-width: 320px;
        width: 100%;

        @include media-breakpoint-down(md) {
            order: 1;
        }
    }

    .slider-arrow {
        top: 40px;

        @include media-breakpoint-up(sm) {
            top: 43px;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            &--left {
                left: -14px;
            }

            &--right {
                right: -14px;
            }
        }
    }

    .slider__wrapper {
        @include media-breakpoint-down(md) {
            @include margin(null -5px);
        }

        &:not(.slick-initialized) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .image-block {
                &:before,
                &:after {
                    display: none;
                    padding-top: 0;
                }

                &__wrapper,
                &__link {
                    display: none;
                }

                &__content {
                    @include padding(3px 10px);
                }

                &__title-link {
                    font-family: $ff-tertiary;
                    white-space: nowrap;
                    text-transform: none;
                    color: $body-color;
                    font-weight: 400;
                    font-size: 12px;

                    &.is-active {
                        color: $primary;
                    }
                }
            }
        }
    }

    .image-block__title-link {
        color: $white;
        font-size: 12px;
    }

    .heading-block__content > & {
        @include media-breakpoint-down(md) {
            order: 2;
            margin-top: 20px;
            margin-bottom: -30px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: -10px;
            margin-bottom: 20px;
        }
    }

    & + .section {
        margin-top: 0;
    }

    &--secondary {
        .tv-menu-slider__search {
            @include media-breakpoint-down(md) {
                order: 3;
            }
        }
    }
}
