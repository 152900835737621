.member-label {
    @include size(91px, 93px);
    font-family: $ff-secondary;
    color: $white;
    background-color: $blue3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &__number {
        font-size: 40px;
        font-weight: 700;
    }

    &__text {
        @include margin(5px null);
        font-weight: 600;
        font-size: 9px;
        text-transform: uppercase;
    }
}
