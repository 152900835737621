.section {
    @include margin(50px null);

    &--no-space {
        & > * {
            script:first-child {
                & + * {
                    margin-top: 0;
                }
            }

            &:first-child {
                @include margin(0 null null null);
            }

            &:last-child {
                @include margin(null null 0 null);
            }

            script + .section {
                @include margin(0 null null null);
            }
        }
    }

    &--no-space-top {
        & > * {
            &:first-child {
                @include margin(0 null null null);
            }
        }
    }

    &--no-space-bottom {
        & > * {
            &:last-child {
                @include margin(null null 0 null);
            }
        }
    }
}
