.header {
    position: relative;
    z-index: $z-header;

    &__main {
        @include padding(null null 10px null);

        @include media-breakpoint-up(lg) {
            @include padding(null null 10px null);
            box-shadow: 0 7px 4px -5px rgba(0, 0, 0, 0.1);
        }

        @include media-breakpoint-up(xl) {
            height: $h-header-xl;
        }
    }

    &__container {
        @include padding(10px null null null);
        display: flex;
        flex-direction: column;
    }

    &__top {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__bottom {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__right {
        @include position(absolute, 0 0 null null);
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__search {
        @include position(absolute, 10px null null 30px);
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__popular {
        @include margin(10px null 20px);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .logo {
        height: 40px;

        @include media-breakpoint-up(lg) {
            height: 60px;
        }
    }
}
