.featured-content {
    @include media-breakpoint-down(xs) {
        @include margin(null -15px);
    }

    &__container {
        margin-top: 20px;
        position: relative;
    }

    &__item {
        @include padding(null 15px);

        @include media-breakpoint-up(sm) {
            @include padding(null 20px);
        }

        @include media-breakpoint-up(md) {
            @include padding(null 30px);
        }
    }

    &__slider {
        @include margin(null -15px);

        @include media-breakpoint-up(md) {
            @include margin(null -30px);
        }
    }

    .dots-block {
        @include margin(30px null 0);

        @include media-breakpoint-up(md) {
            @include margin(0 null null null);
            @include position(absolute, null null 5px 41%);

            @at-root .featured-content .is-sliding .dots-block {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .featured-news {
        &__image-container {
            @include margin(null -10px);

            @include media-breakpoint-up(sm) {
                @include margin(null 0);
            }
        }

        &__content {
            @include media-breakpoint-up(md) {
                @include padding(null null 30px);
            }

            @include media-breakpoint-up(lg) {
                @include padding(null null 40px null);
            }
        }

        &__button {
            @include media-breakpoint-up(md) {
                @include margin(20px null null null);
            }

            @include media-breakpoint-up(lg) {
                @include margin(30px null null null);
            }
        }
    }

    &--bg {
        .featured-content__container {
            @include padding(60px 15px 30px);
            background-color: $light-red2;

            @include media-breakpoint-up(md) {
                @include padding(35px 30px 25px);
            }
        }

        .heading-bg {
            @include margin(null null -45px);

            @include media-breakpoint-up(md) {
                @include margin(null null -15px);
            }

            & + .featured-content__container {
                margin-top: 0;
            }
        }
    }

    &--v2 {
        @include media-breakpoint-down(xs) {
            @include margin(null 0);
        }

        @include media-breakpoint-up(xs) {
            .featured-content__item {
                @include padding(null 15px);
            }
        }

        @include media-breakpoint-up(md) {
            .featured-news__image-block {
                flex-basis: 45%;
            }

            .featured-content__slider {
                @include margin(null -15px);
            }

            .featured-news__content {
                flex-basis: 55%;
            }

            .dots-block {
                left: 50%;
            }
        }
    }

    img {
        max-width: 100%;
    }

    .slider-arrow {
        top: 140px;

        @include media-breakpoint-up(sm) {
            top: 174px;
        }

        @include media-breakpoint-up(md) {
            top: 74px;
        }

        @include media-breakpoint-up(lg) {
            top: 92px;
        }

        @include media-breakpoint-up(xl) {
            top: 108px;
        }

        @at-root .featured-content .is-sliding ~ .slider__arrows .slider-arrow {
            opacity: 0;
            visibility: hidden;
        }

        &--left {
            left: 0;

            @include media-breakpoint-up(sm) {
            }
        }

        &--right {
            right: 0;

            @include media-breakpoint-up(sm) {
            }

            @include media-breakpoint-up(md) {
                right: auto;
                left: 196px;
            }

            @include media-breakpoint-up(lg) {
                left: 242px;
            }

            @include media-breakpoint-up(xl) {
                left: 295px;
            }
        }
    }

    &.featured-content--v2 {
        .slider-arrow {
            @include media-breakpoint-up(md) {
                top: 100px;
            }

            @include media-breakpoint-up(lg) {
                top: 118px;
            }

            @include media-breakpoint-up(xl) {
                top: 142px;
            }

            &--left {
                left: -15px;

                @include media-breakpoint-up(sm) {
                    left: 0;
                }
            }

            &--right {
                right: -15px;

                @include media-breakpoint-up(sm) {
                    right: 0;
                }

                @include media-breakpoint-up(md) {
                    right: auto;
                    left: 265px;
                }

                @include media-breakpoint-up(lg) {
                    left: 324px;
                }

                @include media-breakpoint-up(xl) {
                    left: 391px;
                }
            }
        }
    }

    &.featured-content--bg {
        .slider-arrow {
            top: 190px;

            @include media-breakpoint-up(sm) {
                top: 217px;
            }

            @include media-breakpoint-up(md) {
                top: 100px;
            }

            @include media-breakpoint-up(lg) {
                top: 118px;
            }

            @include media-breakpoint-up(xl) {
                top: 142px;
            }

            &--left {
                @include media-breakpoint-up(sm) {
                    left: 20px;
                }

                @include media-breakpoint-up(md) {
                    left: 30px;
                }
            }

            &--right {
                @include media-breakpoint-up(sm) {
                    right: 20px;
                }

                @include media-breakpoint-up(md) {
                    right: auto;
                    left: 206px;
                }

                @include media-breakpoint-up(lg) {
                    left: 251px;
                }

                @include media-breakpoint-up(xl) {
                    left: 304px;
                }
            }
        }
    }
}
