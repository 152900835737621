.question {
    @include margin(35px null null null);
    @include padding(null null null 45px);
    position: relative;

    @include media-breakpoint-up(sm) {
        @include margin(40px null null null);
    }

    @include media-breakpoint-up(md) {
        @include margin(50px null null null);
    }

    @include media-breakpoint-up(lg) {
        @include margin(60px null null null);
    }

    &:before {
        @include position(absolute, 0 null null 0);
        @include size(32px, 44px);
        content: '';
        display: block;
        background: url('#{$images}icon-question.png') 0 0 no-repeat;
    }

    &__title {
        @include margin(0 null);
        font-size: 22px;

        a {
            color: inherit;
        }
    }

    &__description {
        @include margin(15px null 15px null);
        font-family: $ff-tertiary;
        font-size: 14px;
        line-height: 1.4;
    }

    .btn {
        @include media-breakpoint-up(md) {
            @include padding(.2rem .75rem);
            font-size: .6rem;
            min-width: 0;
        }
    }
}
