.breadcrumb {
    @include margin(25px null 20px);
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &__current {
        @include margin(null 15px null null);
        font-family: $ff-secondary;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
    }

    &-item {
        &,
        &:hover {
            font-size: 12px;
            color: $black;
        }

        &:before {
            font-size: 10px;
        }
    }

    & + .section {
        margin-top: 20px;
    }
}
