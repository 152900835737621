.heading-block {
    position: relative;

    @include media-breakpoint-down(xs) {
        @include margin(null -15px);
    }

    &__placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__title {
        @include margin(0 null);
        display: block;
        color: $gray-4;
        font-size: 32px;
        text-align: center;
        line-height: 1;
    }

    &__description {
        @include margin(null null 0 null);
        text-align: center;
        font-size: 23px;

        &--sm {
            font-family: $ff-tertiary;
            font-size: 14px;
        }
    }

    &__image-wrapper {
        @include size(100%, 230px);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include media-breakpoint-up(xl) {
            height: 300px;
        }

        &--start {
            justify-content: flex-start;

            .heading-block__image {
                width: auto;
                height: auto;
                object-fit: none;
            }
        }

        &--sm {
            height: 190px;
        }

        &--lg {
            @include media-breakpoint-up(xl) {
                height: 350px;
            }
        }
    }

    &__image {
        @include size(100%);
        object-fit: cover;
    }

    &__logo {
        @include position(absolute, null null 20px 50%);
        transform: translate3d(-50%, 0, 0);

        @include media-breakpoint-up(sm) {
            bottom: 30px;
        }
    }

    &__label {
        @include margin(-20px auto null);
        position: relative;
        z-index: 1;
        text-align: center;

        &-element {
            display: inline-block;
            min-width: auto;
            color: $white;
            background-color: $secondary;
            border-radius: 3px;
            font-family: $ff-secondary;
            font-weight: 700;
            font-size: 10px;
            text-align: center;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                @include padding(11px 20px);
                min-width: 300px;
                font-size: 12px;
            }
        }
    }

    &__content {
        @include margin(20px null null null);
        font-family: $ff-tertiary;
        color: $gray-4;

        &--xs {
            font-size: 14px;
        }

        &--xl-small {
            @include media-breakpoint-up(xl) {
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }

    &__fixed-search {
        display: flex;
        position: relative;
        justify-content: flex-end;

        .form-control {
            @include border-top-radius(0);
            @include border-bottom-radius(10px);
            @include placeholder-color($black);
            @include size(220px, 32px);
            border-top: 0;
            padding-right: 30px;
            background-color: $light-red;
            font-weight: 600;
            color: $black;
        }

        button {
            @include position(absolute, 0 0 0 null);
            width: 30px;
        }
    }

    // Pregnancy page
    &--top {
        .heading-block {
            &__placeholder {
                @include padding(15px 30px 75px);
                @include position(absolute, 0 null null 50%);
                transform: translate3d(-50%, 0, 0);
                width: 300px;
                background-position: left 0 bottom 0;
                background-repeat: no-repeat;
                background-image: url('#{$images}bg-heading-white-xs.png');

                @include media-breakpoint-up(sm) {
                    width: 440px;
                    background-image: url('#{$images}bg-heading-white-sm.png');
                }

                @include media-breakpoint-up(xl) {
                    @include padding(15px 65px 150px);
                    width: 600px;
                    background-image: url('#{$images}bg-heading-white-lg.png');

                    &--xl {
                        @include padding(15px 90px 120px);
                        width: 970px;
                        background-image: url('#{$images}bg-heading-white-lg-wide.png');
                        background-position: left 0 bottom -10px;
                    }

                    &--notext {
                        @include padding(null null 75px null);
                    }
                }
            }

            &__title {
                max-height: 102px;
                overflow: hidden;
                white-space: nowrap;
                min-height: 36px;
            }

            &__title-group {
                @include position(absolute, 0 0 null 0);
                @include padding(30px 15px null);

                .heading-block__title {
                    color: $white;
                    white-space: normal;
                    text-transform: uppercase;
                }

                .dropdown {
                    @include margin(30px auto null);
                    @include position(static, auto auto auto auto);
                    transform: none;
                    text-align: center;
                    max-width: 200px;
                }
            }

            &__content {
                @include media-breakpoint-down(xs) {
                    @include padding(null 15px);
                }

                @include media-breakpoint-up(xl) {
                    @include margin(0 null null null);
                    @include padding(null 75px);
                    @include position(absolute, 55px 0 null 0);
                    font-size: 12px;
                    height: 36px;
                    overflow: hidden;
                    line-height: 1.5;

					&--xl {
						@include padding(null 90px);
                        height: 54px;
					}
                }
            }
        }
    }

    // Baby names
    &--bottom {
        .heading-block {
            &__placeholder {
                @include padding(60px 40px 10px);
                @include position(absolute, null null 0 50%);
                width: 440px;
                min-height: 120px;
                transform: translate3d(-50%, 0, 0);

                @include media-breakpoint-up(xl) {
                    @include padding(null 80px 20px);
                    width: 600px;
                    min-height: 130px;
                }

                &:before {
                    @include position(absolute, null 0 0 0);
                    @include size(100%);
                    content: '';
                    background-position: left 0 top 0;
                    background-repeat: no-repeat;
                    background-image: url('#{$images}bg-heading-white-sm.png');
                    z-index: -1;
                    opacity: .95;

                    @include media-breakpoint-up(xl) {
                        background-image: url('#{$images}bg-heading-white-lg.png');
                    }
                }

                &--xs {
                    @include media-breakpoint-down(md) {
                        @include padding(0 30px);
                        width: 275px;
                        height: 76px;
                        min-height: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .heading-block__title {
                            font-size: 22px;
                            font-weight: 400;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }

                    &:before {
                        @include media-breakpoint-down(md) {
                            background-image: url('#{$images}bg-heading-white-xxs.png');
                        }
                    }
                }

                &--xxs {
                    min-height: 100px;
                }

                &--xxs-2 {
                    @include padding(0);
                    width: 275px;
                    height: 58px;
                    padding-top: 20px;
                    min-height: 0;

                    img {
                        max-height: 30px;
                    }

                    &:before {
                        background-image: url('#{$images}bg-heading-white-xxs.png');
                    }
                }
            }

            &__title {
                font-size: 26px;

                @include media-breakpoint-up(xl) {
                    font-size: 32px;
                }
            }

            &__description {
                @include margin(5px null null null);
                font-size: 18px;
                line-height: 1.3;
                max-height: 46px;
                overflow: hidden;

                @include media-breakpoint-up(xl) {
                    @include margin(10px null null null);
                    font-size: 23px;
                    line-height: 1.2;
                    max-height: 55px;
                }
            }

            &__content {
                @include padding(null 15px);
                display: flex;
                flex-direction: column;
            }

            &__search-form {
                @include margin(null auto 20px);
                max-width: 270px;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    @include margin(20px null 0);
                }
            }
        }
    }

    // Voices
    &--bottom-2 {
        .heading-block {
            &__placeholder {
                @include padding(60px 40px 10px);
                @include position(absolute, null null 0 50%);
                width: 300px;
                height: 265px;
                bottom: -150px;
                transform: translate3d(-50%, 0, 0);

                @include media-breakpoint-up(xl) {
                    @include padding(null 80px 20px);
                    width: 970px;
                    height: 262px;
                    bottom: -61px;
                }

                &:before {
                    @include position(absolute, null 0 0 0);
                    @include size(100%);
                    content: '';
                    background-position: left 0 top 0;
                    background-repeat: no-repeat;
                    background-image: url('#{$images}bg-heading-white-xs.png');
                    z-index: -1;
                    opacity: .95;

                    @include media-breakpoint-up(xl) {
                        background-image: url('#{$images}bg-heading-white-lg-wide.png');
                    }
                }
            }

            &__content {
                @include padding(null 15px);
                text-align: center;

                @include media-breakpoint-up(xl) {
                    @include padding(null 120px);
                    @include position(absolute, null 0 10px 0);
                    max-height: 86px;
                    overflow: hidden;
                }
            }
        }
    }

    > .heading-block__title {
        @include padding(20px 30px null);
        @include position(absolute, 0 0 null 0);
        width: 100%;
        text-align: center;
        color: $white;
        white-space: normal;
    }

    .dropdown {
        @include position(absolute, 170px null null 50%);
        transform: translate3d(-50%, 0, 0);

        @include media-breakpoint-up(xl) {
            top: 190px;
        }

        .btn {
            &:hover {
                opacity: 1;
            }
        }
    }
}
