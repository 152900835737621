.text {
    font-size: 1rem;

    &-xxxs {
        font-size: .5625rem;
    }

    &-xxs {
        font-size: .625rem;
    }

    &-xs {
        font-size: .75rem;
    }

    &-sm {
        font-size: .875rem;
    }

    &-lg {
        font-size: 1.125rem;
    }

    &-sub-title {
        font-size: 75%;
        opacity: .75;

        @include media-breakpoint-down(sm) {
            display: block;
            text-align: center;
        }
    }
}
