.article-ad {
    &__title {
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-8;
    }

    &__content {
        @include margin(10px null null null);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__image-container {
        flex: 0 0 auto;
        max-width: 150px;
    }

    &__description {
        font-family: $ff-tertiary;
        font-size: 12px;
        line-height: 1.5;
        color: $gray-10;

        .article-ad__image-container + & {
            padding-left: 20px;
        }
    }
}
