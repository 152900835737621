.icon-play {
    color: $primary;
    font-size: 45px;
    line-height: 1;
    transition: color $transition-duration-medium $transition-timing-function;

    &:before {
        @include position(absolute, 50% null null 50%);
        @include size(80%);
        background-color: $white;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: block;
        content: '';
        z-index: -1;
    }

    .image-block & {
        @include position(absolute, 50% null null 50%);
        transform: translate3d(-50%, -50%, 0);
    }

    .image-block:hover & {
        color: lighten($primary, 5%);
    }

    &--lg {
        font-size: 65px;
    }

    &--sm {
        font-size: 30px;
    }
}