.bg-icon-block {
    @include margin(30px null null);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include media-breakpoint-up(sm) {
        @include margin(null 3% null null);
        width: 115px;
    }

    &__title {
        @include margin(10px null null null);
        font-family: $ff-tertiary;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
        text-transform: uppercase;
        color: $black;

        @include media-breakpoint-up(sm) {
            font-family: $ff-primary;
            font-weight: 400;
        }

        &:hover {
            color: $black;
            opacity: .7;
        }
    }

    &__description {
        display: none;

        @include media-breakpoint-down(xs) {
            @include margin(3px null null null);
            display: block;
        }
    }
}
