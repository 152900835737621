.bg-icon-block-list {
    @include margin(10px null null);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
}
