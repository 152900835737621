.dots-block {
    @include margin(20px null 20px -4px);
    @include padding(null null null 0);
    display: flex !important; // Slick slider sometimes adds inline style
    flex-direction: row;
    justify-content: center;

    li {
        @include padding(4px);
        font-size: 0;
        cursor: pointer;
    }

    &__item {
        @include size(7px);
        background-color: $gray-6;
        border-radius: 50%;
        display: block;
        font-size: 0;

        .slick-active & {
            background-color: $primary;

            .dots-block--secondary & {
                background-color: $secondary;
            }
        }
    }
}
