.group-1 {
    @include margin(20px -15px);

    @include media-breakpoint-up(sm) {
        @include margin(null 0);
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &__title {
        @include margin(null null 5px null);
        @include padding(null null 15px null);
        min-width: 60%;
        font-size: 22px;
        display: inline-block;
        position: relative;

        &:after {
            @include position(absolute, null null 0 0);
            width: 100%;
            border-bottom: 1px solid $gray-9;
            display: block;
            content: '';
        }

        @include media-breakpoint-down(md) {
            @include padding(10px 20px);
            max-width: 100%;
            display: inline-block;
            background-color: $black;
            min-width: 190px;
            color: $white;
            font-family: $ff-secondary;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__content {
        background-color: $light-red2;
        text-align: left;

        @include media-breakpoint-down(md) {
            @include margin(-22px null null);
            @include padding(40px 15px 15px);
        }

        @include media-breakpoint-up(lg) {
            @include margin(15px null null null);
            background-color: transparent;
        }
    }

    .news {
        @include padding(0 null null null);

        &__image-block,
        &__media {
            @include margin(null 0);
        }
    }
}
