.page {
    &__content {
        @extend .section--no-space;

        @include media-breakpoint-down(xs) {
            & > .banner {
                & + * {
                    @include margin(0 null null null);
                }
            }
        }

        &--2-columns {
            @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: row;
            }
        }

        & + & {
            @extend .section;
        }

        .breadcrumb + & {
            @include margin(20px null null);
        }
    }

    &__body {
        @extend .section--no-space;
        min-width: 0;

        @include media-breakpoint-up(lg) {
            flex: 1;
        }
    }

    &__aside {
        @extend .section--no-space;

        @include media-breakpoint-down(md) {
            @include margin(45px null null null);
        }

        @include media-breakpoint-up(lg) {
            @include margin(null null null $page-aside-ml);
            width: $page-aside;
        }
    }
}
