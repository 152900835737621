.pagination {
    @include margin(10px null);
    @include pagination-color($primary, $light-red);

    &--secondary {
        @include pagination-color($secondary, $light-blue2);
    }

    &--bg {
        @include padding(5px null 3px);
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .page-link {
            color: $gray-5;
        }
    }
}

.page-item {
    position: relative;

    &__nav {
        @include size(20px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 12px;
        color: $white;
        z-index: 2;

        i {
            color: $white;
            font-size: 12px;
        }

        &--first {
            @include margin(null 5px null null);
        }

        &--last {
            @include margin(null null null 5px);
        }

        &--prev {
            @include margin(null 15px null null);

            i {
                @include margin(null 0.15em null null);
            }

            .pagination--bg & {
                margin-right: 7px;
            }
        }

        &--next {
            @include margin(null null null 15px);

            i {
                @include margin(null null null 0.15em);
            }

            .pagination--bg & {
                margin-left: 7px;
            }
        }
    }

    &--separator {
        display: flex;
        flex-direction: row;

        &:before {
            font-weight: 600;
            content: '|';
            position: relative;
            top: -2px;
        }
    }
}

.page-link {
    font-family: $ff-secondary;
    font-weight: 700;
    font-size: 12px;

    .pagination &.is-current {
        cursor: default;
    }

    .block-1 &.is-current {
        color: $black;
        cursor: default;
    }
}
