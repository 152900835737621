.bg-icon {
    @include size(52px);
    font-size: 28px;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 1;
    color: $white;

    &[href] {
        &,
        &:hover {
            color: $white;
        }
    }

    &__image {
        max-width: .9em;
        margin-top: -.02em;
    }

    &__text,
    &__description {
        font-family: $ff-secondary;
        text-transform: uppercase;
    }

    &__text {
        margin-top: .02em;
        font-size: .5em;
        font-weight: 600;
    }

    &__description {
        font-size: .28em;
        font-weight: 400;
        margin-top: .03em;
    }

    &--xs {
        @include size(36px);
        color: $white;
        font-size: 20px;
    }

    &--drawing {
        @include size(65px, 62px);
        min-width: 65px;
        background: url('#{$images}pancake-red.png') 0 0 no-repeat;
        background-color: transparent;
        border-radius: 0;

        .bg-icon__image {
            max-width: 45px;
            max-height: 45px;
        }
    }

    &--black {
        background-color: $black;
    }

    .fa-caret-right {
        position: relative;
        left: 2px;
    }

    .fa-caret-left {
        position: relative;
        right: 2px;
    }
}
