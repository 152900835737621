.trimester-block {

    &__weeks {
        @include padding(5px 15px);
        border: 3px solid $primary;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__week {
        font-family: $ff-secondary;
        font-weight: 600;
        font-size: 12px;
        flex: 0 0 28%;
        white-space: nowrap;

        &:nth-child(4n) {
            flex: 0 0 16%;
        }
    }

    &--secondary {
        .trimester-block__weeks {
            border-color: $secondary;
        }
    }

    & + & {
        margin-top: 5px;
    }

    .heading-bg {
        font-size: 18px;
        margin-left: -15px;
        letter-spacing: 1px;
    }
}