.names-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__group-name {
        font-size: 19px;
        margin-bottom: 5px;
        color: $gray-5;
        flex-basis: 100%;
    }

    &__item {
        position: relative;
        flex-basis: 33%;
        padding: 6px 10px 6px 25px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__link {
        font-family: $ff-secondary;
        font-size: 14px;
        font-weight: 700;

        &,
        &:hover {
            color: $gray-10;
        }
    }

    &__close {
        position: absolute;
        top: 8px;
        left: 3px;
        font-size: 18px;
        line-height: 1;
        display: block;
    }

    &--column {
        .names-list__item {
            .block-1__full & {
                @include padding(null null null 0);
            }
            flex-basis: 100%;
        }
    }

    &--2-columns {
        .names-list__item {
            .block-1__full & {
                @include padding(null null null 0);
            }
            flex-basis: 50%;
        }
    }

    &--4-columns {
        .names-list__item {
            .block-1__full & {
                @include padding(null null null 0);
            }
            flex-basis: 33%;

            @include media-breakpoint-up(sm) {
                flex-basis: 25%;
            }
        }
    }

    &--5-columns {
        .names-list__item {
            .block-1__full & {
                @include padding(null null null 0);
            }
            flex-basis: 33%;

            @include media-breakpoint-up(sm) {
                flex-basis: 20%;
            }
        }
    }
}
