.btn-fav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: $gray-10;
    font-family: $ff-tertiary;
    font-weight: 600;
    font-size: 12px;

    &:hover {
        color: $gray-10;
        opacity: 1;

        i {
            opacity: 1;
        }

        span {
            opacity: .7;
        }
    }

    i {
        @include margin(null 5px null null);
        font-size: 16px;
        color: $gray-1;
    }
}
