.heading-secondary {
    @include padding(null null 7px null);
    position: relative;

    &,
    a {
        font-family: $ff-secondary;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: $body-color;
    }

    &:after {
        @include position(absolute, null null 0 0);
        @include size(40px, 7px);
        background-color: $primary;
        content: '';
        display: block;
    }
}
