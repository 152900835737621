.heading-bg {
    @include margin(0 null);
    @include padding(null 20px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: $ff-primary;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    z-index: 1;

    &:before {
        @include position(absolute, null 0 1px 0);
        content: '';
        display: block;
        z-index: -1;
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    &__text {
        display: block;
        overflow: visible;
    }

    &__image {
        @include position(absolute, null null 0 0);
        max-width: 75px;
    }

    &--primary {
        @include heading-bg-image('heading-bg-red-xs.png', 290px, 33px, true);
        @include padding(null 25px);
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            @include heading-bg-image('heading-bg-red-1.png', 426px, 43px, true);
            @include padding(null 50px);
            font-size: 24px;
        }
    }

    &--primary-lg {
        @extend .heading-bg--primary;

        @include media-breakpoint-up(md) {
            @include heading-bg-image('heading-bg-red-2.png', 671px, 55px, true);
            font-size: 28px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 32px;
        }
    }

    &--primary-sm {
        @include heading-bg-image('heading-bg-red-xs.png', 290px, 33px, true);
        @include padding(null 25px);
        font-size: 18px;

        @include media-breakpoint-up(sm) {
            @include heading-bg-image('heading-bg-red-1.png', 426px, 43px, true);
            @include padding(null 50px);
            font-size: 24px;
        }

        @include media-breakpoint-up(lg) {
            @include heading-bg-image('heading-bg-red-xs.png', 290px, 33px, true);
            @include padding(null 25px);
            font-size: 18px;
        }
    }

    &--primary-md {
        @include padding(null 0 null 25px);
        @include heading-bg-image('heading-bg-red-3.png', 290px, 26px, true, false);
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        color: $black;
        text-align: left;
        justify-content: flex-start;
    }

    &--secondary {
        @include padding(null 0 null 25px);
        @include heading-bg-image('heading-bg-blue-1.png', 290px, 26px, true, false);
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        color: $black;
        text-align: left;
        justify-content: flex-start;
    }

    &--primary-xs,
    &--secondary-xs {
        @include padding(null 30px);
        font-size: 20px;
        text-align: left;
        justify-content: flex-start;
    }

    &--primary-xs {
        @include heading-bg-image('heading-bg-red-xs-2.png', 225px, 20px, true, false);
    }

    &--secondary-xs {
        @include heading-bg-image('heading-bg-blue-xs-2.png', 193px, 20px, true, false);
    }

    &--secondary-lg {
        @include heading-bg-image('heading-bg-blue-xs.png', 290px, 33px, true);
        @include padding(null 25px);
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            @include heading-bg-image('heading-bg-blue-lg-1.png', 426px, 43px, true);
            @include padding(null 50px);
            font-size: 24px;
        }
    }

    &--secondary-style {
        font-family: $ff-tertiary;
        font-size: 20px;
        font-weight: 600;
        color: $black;
    }

    &--with-image {
        padding-left: 80px;
    }

    &--notes {
        padding-left: 70px;

        .heading-bg__image {
            left: 20px;
            bottom: 0;
        }
    }

    &--center {
        @include margin(null auto);
        display: flex;
    }

    &--top {
        &:before {
            bottom: auto;
            top: 3px;
        }
    }
}
