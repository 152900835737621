.spinner-item {
    @include margin(null auto);
    max-width: 230px;

    &__body {
        @include margin(15px null null);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
        border: 2px solid $primary;
        overflow: hidden;
    }

    &__body,
    &__unit {
        height: 90px;
        border-radius: 8px;
    }

    &__unit {
        @include padding(null 15px);
        color: $gray-3;
        font-size: 22px;
        line-height: 1.2;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
    }

    &__button {
        @include margin(15px auto null);
        display: block;
        max-width: 75%;
        width: 100%;
        min-width: 0;
        opacity: 1;
        transition: opacity $transition-duration $transition-timing-function;

        &.is-active {
            pointer-events: none;
            opacity: .5;
        }
    }
}
