.footer {
    @include margin(30px null null null);
    background-color: $light-red;
    position: relative;
    z-index: $z-footer;

    &,
    a,
    a:hover {
        &:not(.btn) {
            font-size: 14px;
            color: $gray-3;
        }
    }

    &__container {
        @include padding(20px 20px 40px);
        text-align: center;
    }

    .logo {
        height: 53px;
    }
}
