html {
    @include size(100%);
    font-size: 16px;
}

body {
    @include size(100%);
    min-width: $page-min-width;
}

main {
    position: relative;
    z-index: $z-main;
}

button {
    @include padding(null 0);
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 0;
    transition: opacity $transition-duration $transition-timing-function, color $transition-duration $transition-timing-function, background-color $transition-duration $transition-timing-function;

    &,
    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: .7;
    }
}

a {
    transition: opacity $transition-duration $transition-timing-function, color $transition-duration $transition-timing-function;

    &:hover {
        opacity: .7;
    }
}

video {
    max-width: 100%;
}

.flex-1 {
    flex: 1;

    &-xs {
        @include media-breakpoint-up(xs) {
            flex: 1;
        }
    }
}
