.date-block {
    margin-top: 15px;
    font-size: 14px;

    &__title {
        font-family: $ff-primary;

        @include media-breakpoint-down(xxs) {
            text-align: center;
        }
    }

    &--submit-button {
        .date-block__fields {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @include media-breakpoint-down(xxs) {
                flex-wrap: wrap;
            }

            > .form-group {
                width: 100%;

                @include media-breakpoint-down(xxs) {
                    flex: 0 0 100%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    @include media-breakpoint-down(xxs) {
                        flex: 0 0 calc(50% - 2.5px);
                    }
                }

                @include media-breakpoint-up(xs) {
                    @include margin(null null 0 null);
                    flex: .8;
                }

                &:not(:first-child) {
                    @include media-breakpoint-up(xs) {
                        margin-left: 5px;
                    }
                }

                &:first-child {
                    @include media-breakpoint-up(xs) {
                        flex: 1.5;
                    }
                }

                &:last-child {
                    @include media-breakpoint-up(xs) {
                        flex: .9;
                    }
                }
            }

            .btn {
                min-width: 0;
                width: 100%;
            }
        }
    }

    &--icon-submit {
        .date-block__fields {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            > .form-group {
                flex: 0 0 calc(50% - 2.5px);

                &:nth-child(1) {
                    flex: 0 0 calc(100% - 75px);
                }

                &:nth-child(2) {
                    margin-top: -20px;
                    flex: 0 0 70px;
                }
            }
        }
    }
}
