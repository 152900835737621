.banner-bg {
    display: none;

    @include media-breakpoint-up(xl) {
        @include margin(0 auto);
        @include position(absolute, 158px 0 null 0);
        display: flex;
        justify-content: center;
        z-index: $z-banner-full-bg;
        overflow: hidden;

        .banner-bg__media {
            min-width: 1600px;
        }
    }

    a {
        &,
        &:hover {
            opacity: 1;
        }
    }
}
