.font-secondary {
    font-family: $ff-secondary;
}

.font-tertiary {
    font-family: $ff-tertiary;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}
