.remember-block {
    @include margin(20px null);
    @include padding(15px 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $light-red;
    color: $primary;
    font-weight: 600;
    border-radius: 5px;

    @include media-breakpoint-up(sm) {
        @include padding(30px 20px);
        flex-direction: row;
    }

    &__icon {
        margin-bottom: 5px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            position: relative;
            top: -5px;
        }
    }

    .btn {
        @include padding(null 25px);
        margin-top: 10px;
        min-width: 0;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }
}
