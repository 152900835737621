.expert-block {
    @include margin(35px auto null);

    @include media-breakpoint-down(xxs) {
        max-width: 195px;
    }

    &__content {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .expert-block--static & {
                display: block;
            }
        }
    }

    &__image-container {
        flex: 0 0 120px;
        margin-right: 15px;

        .expert-block--static & {
            width: 100%;
        }
    }

    &__data {
        margin-top: 10px;
        font-family: $ff-secondary;
        font-size: 10px;
        color: $gray-5;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-up(xl) {
            margin-top: 0;
            text-align: left;
        }
    }

    &__name {
        font-family: $ff-primary;
        font-weight: 400;
        text-transform: none;
        font-size: 22px;
        line-height: 1.2;
    }

    &__position {
        @include margin(5px null 2px);
    }

    &__button {
        margin-top: 10px;

        .btn {
            min-width: 0;
            width: 100%;
            font-size: 10px;

            @include media-breakpoint-up(xl) {
                width: 120px;

                .expert-block--static & {
                    width: 100%;
                }
            }
        }
    }

    &--static {
        margin-top: 0;
    }
}
