.logo {
    display: inline-block;
    position: relative;
    z-index: 2;

    &:hover {
        opacity: 1;
    }

    &__image {
        display: block;
        max-height: 100%;
    }

    &-tv {
        &,
        .logo__image {
            max-height: 80px;

            @include media-breakpoint-up(sm) {
                max-height: 100px;
            }
        }

        &--sm {
            &,
            .logo__image {
                max-height: 50px;

                @include media-breakpoint-up(sm) {
                    max-height: 70px;
                }
            }
        }
    }
}
