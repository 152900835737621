.news-list {
    &__content {
        display: grid;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }

        .tag + & {
            @include margin(30px null null null);
        }
    }

    &__item {
        &--banner-2x {
            display: none;

            @include media-breakpoint-up(xl) {
                grid-column: 2;
                grid-row: 2 / 3 div;
                display: block;
            }
        }
    }

    &__action {
        @include margin(20px null null);
        text-align: center;
    }

    &--v2 {
        .news-list__content {
            @include media-breakpoint-up(sm) {
                @include margin(25px null null null);
                grid-template-columns: repeat(2, 1fr);
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    &--v3 {
        .news-list__content {
            @include media-breakpoint-up(sm) {
                @include margin(25px null null null);
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    &--v4 {
        .news-list__content {
            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(4, 1fr);
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &--image-blocks {
        .news-list__content {
            grid-gap: 10px;

            @include media-breakpoint-up(sm) {
                grid-gap: 15px;
            }

            @include media-breakpoint-up(lg) {
                grid-gap: 20px;
            }
        }

        @include media-breakpoint-down(md) {
            .image-block {
                &:after {
                    @include padding(100px null null null);
                }

                &__image {
                    @include size(100%, auto);
                }
            }
        }
    }

    & + & {
        @include margin(-20px null null null);
    }

    .page__content > & {
        &:first-child {
            @include padding(25px null null null);

            & > .tag {
                @include margin(-25px null null null);
            }
        }
    }

    .news {
        @include margin(0 null null null);
    }

    .banner {
        @include margin(14px null 0);
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            max-width: 300px;
        }
    }
}
