.author-card {
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    &__image {
        @include media-breakpoint-down(xs) {
            @include margin(null auto);
            max-width: 175px;
        }

        @include media-breakpoint-up(sm) {
            @include margin(null 20px null null);
            flex-basis: 175px;
        }
    }

    &__data {
        font-family: $ff-secondary;
        font-size: 12px;

        @include media-breakpoint-down(xs) {
            @include margin(10px auto null);
            text-align: center;
            max-width: 350px;
        }

        @include media-breakpoint-up(sm) {
            flex: 1;
        }
    }

    &__name {
        @include margin(0 null 5px);
        font-size: 24px;
        font-family: $ff-primary;

        @include media-breakpoint-up(md) {
            font-size: 26px;
        }
    }

    &__www {
        @include margin(0 null);
        font-family: $ff-tertiary;
        font-weight: 600;
        color: inherit;
        display: inline-block;

        &:hover {
            color: inherit;
        }

        @include media-breakpoint-up(sm) {
            @include margin(null null 5px null);
        }
    }

    &__description {
        @include margin(0 null);
    }

    .image-block__image {
        height: 100%;
    }

    .social-list {
        @include media-breakpoint-down(xs) {
            justify-content: center;
        }
    }

    .bg-icon {
        @include margin(null 5px);

        &:first-child {
            @include media-breakpoint-up(sm) {
                @include margin(null null null 0);
            }
        }
    }
}
