.login-block {
    &__content {
        margin-top: 25px;
        font-family: $ff-secondary;
        font-size: 13px;

        @include media-breakpoint-up(md) {
            height: 270px;
        }

        @include media-breakpoint-only(lg) {
            height: auto;
        }
    }

    &__title {
        font-weight: 600;
    }

    &__description {
        margin-top: 5px;

        li {
            &:not(:first-child) {
                margin-top: 5px;
            }
        }
    }

    &__forgot {
        font-size: 10px;
        text-decoration: underline;

        &,
        &:hover {
            color: inherit;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__remember {
        margin-top: 5px;

        .custom-control-label {
            font-weight: 300;
            text-transform: none;
        }
    }

    &__buttons {
        margin-top: 20px;
    }

    &__or {
        font-size: 12px;

        &:before,
        &:after {
            content: '-';
        }
    }

    &__register-button {
        text-transform: none;

        span {
            font-weight: 400;
        }

        strong {
            font-weight: 600;
            text-decoration: underline;
        }

        &:hover {
            strong {
                text-decoration: none;
            }
        }
    }

    .form-control {
        height: 40px;
    }

    .input-group-prepend {
        border-right: 0;

        & + .form-control {
            padding-left: 0;
            border-left: 0;
        }
    }

    .input-group-text {
        background-color: transparent;
        width: 40px;
        color: initial;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .heading-bg {
        align-items: flex-end;
        width: 145px;

        &--with-image {
            width: 300px;
        }
    }
}
