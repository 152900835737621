.sidebar {
    @include position(fixed, 0 0 0 0);
    @include size($sidebar-width, 100%);
    transform: translate3d((-$sidebar-width), 0, 0);
    z-index: $z-sidebar;
    transition: transform 0.4s ease-in-out;
    background-color: $white;

    @include media-breakpoint-up(sm) {
        transform: translate3d((-$sidebar-width-sm), 0, 0);
        width: $sidebar-width-sm;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .is-sidebar & {
        transform: translate3d(0, 0, 0);
    }

    &__header {
        @include padding(10px null 5px null);
        position: relative;
        text-align: center;
    }

    &__content {
        @include padding(15px 20px 0);
        @include position(absolute, 75px 0 0 0);
        @include size(100%, auto);
        overflow: auto;
        display: flex;
        flex-direction: column;
        -webkit-overflow-scrolling: touch;
    }

    &__content-top {
        flex: 1;
    }

    &__content-bottom {
        @include margin(null -20px);
        @include padding(30px null);
        background-color: $light-red;
        font-size: 14px;
    }

    .social-list {
        justify-content: center;

        &__link {
            color: $black;
        }
    }

    .bg-icon-list {
        @include margin(null 0);
    }
}

.is-sidebar {
    body {
        overflow: hidden;
    }
}
