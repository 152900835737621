.tag {
    @include padding(null 20px);
    display: block;
    width: 100%;
    text-align: center;

    &__content {
        @include padding(5px 20px);
        max-width: 100%;
        display: inline-block;
        background-color: $black;
        min-width: 120px;
        color: $white;
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;

        .tag--secondary & {
            background-color: $white;
            color: $gray-5;
        }

        .tag--tertiary & {
            background-color: $white;
            color: $primary;
        }
    }

    &--sm {
        @include padding(null 10px);

        .tag__content {
            @include padding(null 10px);
            font-size: 10px;
            font-weight: 600;
        }
    }

    &--lg {
        .tag__content {
            @include padding(10px 25px);
            font-size: 14px;
            font-weight: 600;
        }
    }
}
