.search-sidebar {
    position: relative;

    &__button {
        @include position(absolute, 5px null null 10px);
        font-size: 18px;
    }

    .form-control {
        @include padding(null null null 55px);
        box-shadow: none;
        border-color: $gray-2;
        color: $black;
    }
}
