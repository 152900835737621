.banner-fixed {
    display: none;

    @include media-breakpoint-up(xl) {
        width: 100%;
        display: block;

        &__container {
            @include margin(null auto);
            width: map-get($container-max-widths, xl);
            display: flex;
            justify-content: space-between;
        }

        &__left {
            .banner-fixed__media {
                transform: translateX(-100%);
            }
        }

        &__media {
            @include margin(15px null null null);
            position: fixed;
            z-index: $z-banner-fixed;
        }
    }

    a {
        &,
        &:hover {
            opacity: 1;
        }
    }
}
