.tv-slider-block {
    .form-group--secondary {
        width: 100%;

        @include media-breakpoint-up(sm) {
            max-width: 310px;
        }
    }

    .slider-4 {
        @include media-breakpoint-up(sm) {
            @include margin(null null 30px null);
        }
    }

    .dots-block {
        @include margin(null null 0 null);

        @include media-breakpoint-up(sm) {
            @include position(absolute, null null null -9999px);
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }
}
