.slider {
    position: relative;

    &__wrapper {
        &:not(.slick-initialized) {
            display: none;
        }
    }

    &__arrows {
        @include position(absolute, 0 0 null 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 0;
    }
}
