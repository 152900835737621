.slider-2 {
    .slider__wrapper {
        overflow: hidden;
    }

    .slick-list {
        @include media-breakpoint-up(xs) {
            margin-left: -15px;
        }
    }

    .slider__arrows {
        top: 14px;
    }

    .slick-arrow {
        top: 101.125px;

        @include media-breakpoint-up(xs) {
            top: 78px;
        }

        @include media-breakpoint-up(sm) {
            top: 64.75px;
        }

        @include media-breakpoint-up(md) {
            top: 58px;
        }

        @include media-breakpoint-up(lg) {
            top: 50.125px;
        }

        @include media-breakpoint-up(xl) {
            top: 46.75px;
        }
    }

    .news {
        @include margin(0 null null null);


        @include media-breakpoint-up(xs) {
            padding-left: 15px;
        }

        &__image-block,
        &__media {
            @include margin(null 0);
        }

        &__title,
        &__link {
            font-size: 14px;
        }
    }
}
