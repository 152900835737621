.weather-widget {
    display: flex;
    justify-content: center;
    position: relative;

    &__widget {
        width: 180px;
        overflow: hidden;
    }

    &__link {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        display: block;
        z-index: 1;
    }

    &__logo {
        margin-top: 8px;
    }

    .header__right & {
        .weather-widget__logo {
            margin-right: 15px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .sidebar & {
        @include margin(-10px null 20px);

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}