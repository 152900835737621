.header-nav {
    position: relative;

    &__content {
        @include padding(10px 15px 0);
        @include position(absolute, null -10px null -10px);
        box-shadow: 0 6px 7px -5px rgba(0, 0, 0, .75);
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        &:before {
            @include position(absolute, 10px 0 0 0);
            box-shadow: inset 0 7px 4px -5px rgba(0, 0, 0, 0.1);
            width: 100%;
            content: '';
            display: block;
            background-color: $white;
        }

        .nav__item:hover & {
            transition: visibility $transition-duration $transition-timing-function, opacity $transition-duration $transition-timing-function;
            visibility: visible;
            opacity: 1;
            z-index: 99;
        }
    }
}
