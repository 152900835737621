.block-1 {
    @include margin(40px null);

    &__content {
        border: 3px solid $primary;
    }

    &__header {
        background-color: $primary;
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: relative;

        @include media-breakpoint-up(md) {
            min-height: 260px;
            flex-direction: row;
        }

        @include media-breakpoint-only(lg) {
            flex-direction: column;
            min-height: 0;
        }
    }

    &__full {
        @include padding(15px 30px);
        position: relative;
    }

    &__side-left {
        @include padding(15px 5px);
        width: 100%;

        @include media-breakpoint-up(md) {
            flex-basis: 60%;
            max-width: 60%;
        }

        @include media-breakpoint-only(lg) {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    &__side-right {
        @include padding(15px 20px 15px null);
        width: 100%;

        @include media-breakpoint-up(md) {
            flex-basis: 40%;
            max-width: 40%;
        }

        @include media-breakpoint-only(lg) {
            max-width: 100%;
            flex-basis: 100%;
        }

        &--bg {
            @include padding(null null null 20px);
            background-color: $gray-12;
        }
    }

    &__title {
        @include margin(null null 10px null);
        font-family: $ff-secondary;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        color: $black;
    }

    &__name-placeholder {
        @include padding(30px 10px);
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-family: $font-family-base;
        color: $primary;
        line-height: 1.2;
        text-align: center;
    }

    &__search-form {
        font-family: $ff-tertiary;
        font-size: 12px;
        font-weight: 600;
    }

    &__switcher {
        @include position(absolute, 15px 20px null null);
    }

    &--secondary {
        .block-1 {
            &__content {
                border: 3px solid $secondary;
            }

            &__header {
                background-color: $secondary;
            }
        }

        .page-item__nav {
            border-color: $secondary;
            background-color: $secondary;
        }

        .page-link {
            color: $secondary;
        }
    }

    .heading-bg {
        @include margin(null null 5px null);

        @include media-breakpoint-up(sm) {
            @include margin(null null null -15px);
        }
    }
}
