.error-page {
    background: radial-gradient(ellipse, white, #88D6D1);

    &__content {
        @include padding(10px 30px 30px);
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__image {
        max-width: 250px;

        @include media-breakpoint-up(md) {
            max-width: 350px;
        }
    }

    &__title {
        color: #F6A0A7;
        font-family: $ff-secondary;
        font-size: 42px;
        font-weight: 900;
        line-height: 1;
        margin-top: 25px;
        text-transform: uppercase;
        text-shadow: -2px 3px #CED0D0;

        @include media-breakpoint-up(md) {
            font-size: 100px;
            text-shadow: -3px 4px #CED0D0;
            margin-top: 50px;
        }
    }

    &__description {
        font-family: $ff-secondary;
        font-size: 11px;
        font-weight: 700;
        color: #333;
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            font-size: 26px;
            font-weight: 300;
        }
    }

    &__button {
        font-family: $ff-tertiary;
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .5px;
        color: $white;
        margin-top: 30px;
        padding: 10px 39px;
        background-color: #F16B65;
        border-radius: 30px;

        @include media-breakpoint-up(md) {
            font-size: 19px;
            padding: 14px 61px;
        }

        &:hover {
            color: $white;
        }
    }
}
