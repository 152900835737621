.tooltip {
    &-inner {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
        font-family: $ff-tertiary;
        font-weight: 600;
        color: $gray-5;
    }

    .arrow {
        background: url('#{$images}tooltip-arrow-white.png') 0 0;
        width: 45px;
        height: 21px;
        left: 20px !important;

        &:before {
            display: none;
        }
    }

    &.bs-tooltip-top {
        padding-bottom: 10px;

        .arrow {
            bottom: -11px;
        }
    }

    &.bs-tooltip-bottom {
        padding-top: 10px;

        .arrow {
            transform: scaleY(-1);
            bottom: auto;
            top: -11px;
        }
    }

    &.bs-tooltip-left {
        padding-right: 5px;
    }

    &.bs-tooltip-right {
        padding-left: 5px;
    }

    &--dark {
        .tooltip-inner {
            background-color: $gray-15;
            color: $white;
        }

        .arrow {
            background: url('#{$images}tooltip-arrow-black.png') 0 0;
        }
    }

    &--love {
        .tooltip-inner {
            background-image: url('#{$images}icon-love.png');
            background-repeat: no-repeat;
            background-position: top 10px right 15px;
            background-size: 43px;
            padding-top: 45px;
        }

        &.tooltip--dark {
            .tooltip-inner {
                background-image: url('#{$images}icon-love-dark.png');
            }
        }
    }

    &--birds {
        .tooltip-inner {
            background-image: url('#{$images}birds-conversation.png');
            background-repeat: no-repeat;
            background-position: top 10px center;
            background-size: 60px;
            padding-top: 65px;
        }
    }
}
