.close-button {
    @include padding(0);
    @include position(absolute, 10px 15px null null);
    @include size(40px);
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        @include size(50%);
        position: relative;
        display: block;

        &:before,
        &:after {
            @include margin(-1px null null null);
            @include position(absolute, 50% null null 0);
            @include size(100%, 2px);
            content: '';
            background: $black;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}