.error-cloud {
    @include position(absolute, -115px 0 null null);
    @include size(115px);
    z-index: 1;

    &:before,
    &:after {
        display: block;
        content: '';
    }

    &:before {
        @include position(absolute, 0 null null 0);
        @include size(91px, 76px);
        background-image: url('#{$images}icon-error-cloud.png');
    }

    &:after {
        @include position(absolute, null 0 0 null);
        @include size(50px);
        background-image: url('#{$images}icon-error-bird.png');
        background-size: 50px;
    }

    &__text {
        @include position(absolute, 9px null null 14px);
        @include size(65px, 45px);
        overflow: hidden;
        font-size: 9px;
        color: $white;
        text-align: center;
        line-height: 1.3;
        font-weight: 600;
        text-transform: uppercase;
    }
}
