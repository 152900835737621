.slider-arrow {
    @include position(absolute, 0 null null null);
    @include size(34px);
    border: 1px solid $primary;
    background-color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;
    font-size: 20px;
    z-index: 2;

    &.slick-disabled {
        opacity: 0;
        visibility: hidden;
    }

    &--secondary {
        @include size(2.25em, 2em);
        background-color: rgba($white, .5);
        border-radius: 1px;
        color: $gray-4;
        border: none;
    }

    &--tertiary {
        @include size(auto);
        font-size: 18px;
        border: none;
        background-color: transparent;
        color: $primary;

        &.slider-arrow {
            top: -1px;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }
    }

    i {
        font-size: 1em;
    }

    &--right,
    &--left {
        i {
            position: relative;
            top: 1px;
        }
    }

    &--left {
        left: -17px;

        i {
            @include margin(null 0.15em null null);
        }
    }

    &--right {
        right: -17px;

        i {
            @include margin(null null null 0.15em);
        }
    }

    &--middle {
        top: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    &--sm {
        font-size: 12px;
    }
}
