.featured-partners-block {
    .news-list {
        &__content {
            @include margin(20px null null null);
            grid-gap: 20px;

            @include media-breakpoint-up(sm) {
                grid-gap: 15px;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    .news {
        @include padding(0 null null null);

        & > .news__image-block,
        & > .news__media {
            @include margin(null 0);
        }

        &__title,
        &__link {
            font-family: $ff-secondary;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;

            @include media-breakpoint-up(sm) {
                font-size: 14px;
            }
        }
    }
}
