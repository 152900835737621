.question-block {
    @include margin(30px null);
    @include padding(30px 15px 20px);
    position: relative;
    border: 14px solid $light-blue1;

    @include media-breakpoint-up(md) {
        @include padding(null 30px);
    }

    &__subject {
        font-size: 20px;
        line-height: 1.2;

        a {
            color: inherit;
        }
    }

    &__description {
        @include margin(15px null null null);
        font-family: $ff-tertiary;
        font-size: 14px;
    }

    &__data {
        @include margin(20px null null null);
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
    }

    &__avatar {
        @include margin(null 10px null null);
        @include size(50px);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            @include size(100%);
            object-fit: cover;
        }
    }

    &__icon {
        @include position(absolute, -35px 0 null null);
        z-index: 1;
    }
}
