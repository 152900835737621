.form-control {
    &--secondary {
        font-family: $ff-tertiary;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        color: $gray-5;
    }
}

.custom-select,
.form-control {
    font-family: $ff-tertiary;
    letter-spacing: 1px;
    color: $gray-10;
    font-size: .75rem;
    border-radius: 3px;
    border: 1px solid $gray-11;
}

.custom-select {
    padding-right: 40px;
    position: relative;

    &:before {
        content: '';
        display: block;
    }
}

label,
.form-group__label {
    @include label-style;
}

.input-group-text {
    background-color: $secondary;
    color: $white;
    font-family: $ff-tertiary;
    letter-spacing: 1px;
    font-size: 12px;
}

.form-group {
    margin-bottom: 10px;
    position: relative;

    &__icon {
        @include position(absolute, 50% null null 15px);
        transform: translate3d(0, -50%, 0);

        & + .form-control {
            padding-left: 40px;
        }
    }

    &__icon-button-right {
        @include position(absolute, 0 0 0 null);
        width: 40px;
    }

    &__inline-date {
        @include margin(null -2.5px);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        select {
            @include margin(null 2.5px);
            flex: 1;
        }
    }

    &--secondary {
        .form-control {
            border-color: lighten($primary, 25%);
            border-radius: 0;
        }

        i {
            color: $primary;
        }
    }

    img {
        max-width: 100%;
    }
}

.form-group,
.custom-control {
    &.has-error {
        label {
            color: $primary;
        }

        .form-control {
            border-color: $primary;
            box-shadow: 0 0 3px 1px rgba($primary, 1);
        }

        .custom-control-label {
            &:before {
                border-color: $primary;
                box-shadow: 0 0 3px 1px rgba($primary, 1);
            }
        }
    }
}

.form-check {
    label {
        text-transform: uppercase;
        font-weight: normal;
        font-size: .75rem;
    }
}

.form-check-label {
    text-transform: uppercase;
    font-weight: normal;
    font-size: .75rem;
}

.custom-radio,
.custom-checkbox {
    min-height: $custom-checkbox-size;
    padding-left: 0;

    &:not(.custom-control-inline) {
        & + & {
            margin-top: 5px;
        }
    }

    .custom-control-label {
        min-height: $custom-checkbox-size;
        padding-left: $custom-checkbox-size + .5rem;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: .625rem;
        font-weight: 500;

        &__default {
            @include label-style;
        }

        &.custom-control-label--bottom {
            padding-bottom: $custom-checkbox-size + .25rem;
            padding-left: 0;

            &:before,
            &:after {
                top: auto;
                bottom: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
            }
        }

        &:before {
            border: 1px solid $gray-400;
        }

        &:before,
        &:after {
            @include size($custom-checkbox-size);
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
        }
    }
}

.custom-control-inline {
    min-height: $input-height;
    align-items: center;
}

textarea.form-control {
    min-height: 100px;
}

.custom-file {
    &--fixed-width {
        .input-group > & {
            width: 290px;
            flex: 0 0 290px;
        }
    }

    &-input {
        cursor: pointer;

        &:focus ~ .custom-file-label {
            border-color: $gray-400;
            box-shadow: 0 0 0 .2rem rgba($secondary, .25);

            &::after {
                border-color: $secondary;
            }
        }
    }

    &-label {
        &__btn-icon {
            @include position(absolute, .375rem .5rem null null);
            z-index: 4;
            color: $white;
            font-size: 1rem;
        }

        &:after {
            border-top: 1px solid $custom-file-button-bg;
            border-bottom: 1px solid $custom-file-button-bg;
            border-left: 1px solid $custom-file-button-bg;
            top: -1px;
            right: -1px;
            font-size: .75rem;
            font-weight: 600;
            color: $white;
            text-transform: uppercase;
            padding-right: 1.5rem;
        }
    }
}

input[type='search']::-ms-clear {
    display: none;
}
