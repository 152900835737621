.featured-news {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    &__image-block {
        @include media-breakpoint-down(xs) {
            @include margin(null -15px);
        }

        @include media-breakpoint-up(md) {
            flex-basis: 35%;
        }
    }

    &__content {
        @include margin(20px null null);

        @include media-breakpoint-up(md) {
            @include margin(0 null null null);
            @include padding(null null null 5%);
            flex-basis: 65%;
        }
    }

    &__title {
        @include margin(0 null);
        font-size: 26px;
        font-weight: 400;
        line-height: 1.2;
    }

    &__description {
        @include margin(10px null 0 null);
        font-family: $ff-tertiary;
        font-size: 12px;
    }

    &__button {
        @include margin(25px null null);

        @include media-breakpoint-down(sm) {
            @include margin(null auto);
            display: block;
            max-width: 65%;
        }

        @include media-breakpoint-up(md) {
            @include padding(5px 15px);
            min-width: auto;
            font-size: 12px;
        }
    }
}
