.bg-icon-list {
    @include margin(-10px -5px 0);
    @include padding(null 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .bg-icon {
        @include margin(10px 5px 0);
    }
}
