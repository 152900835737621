.group-thread {
    @include border-top-radius(5px);
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .2);

    @include media-breakpoint-up(md) {
        @include padding(10px null);
        flex-direction: row;
        min-height: 120px;
    }

    &__data {
        @include padding(15px);
        order: 2;
        background-color: $gray-9;

        @include media-breakpoint-up(md) {
            @include padding(5px 20px);
            background-color: transparent;
            order: 1;
            text-align: left;
        }
    }

    &__title {
        font-family: $ff-secondary;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;

        a {
            color: inherit;
        }
    }

    &__description {
        font-family: $ff-tertiary;
        font-size: 12px;
        margin-top: 5px;
    }

    &__info {
        order: 1;
        display: flex;
        flex-direction: row;

        @include media-breakpoint-up(md) {
            order: 2;
            flex: 0 0 270px;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-family: $ff-tertiary;
        font-size: 12px;
        font-weight: 600;

        &:not(:first-child) {
            .group-thread__column-name {
                border-left: 1px solid $white;
            }

            .group-thread__column-value {
                border-left: 1px solid $gray-6;
            }
        }

        @include media-breakpoint-up(md) {
            &:first-child {
                border-left: 1px solid $gray-6;
            }
        }
    }

    &__column-name {
        @include padding(7px 5px);
        @include border-top-radius(5px);
        height: 32px;
        overflow: hidden;
        white-space: nowrap;
        background-color: $secondary;
        text-transform: uppercase;
        color: $white;

        @include media-breakpoint-up(md) {
            margin-top: -42px;
        }
    }

    &__column-value {
        @include padding(10px);
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 4px;

        @include media-breakpoint-up(md) {
            margin-top: 10px;
            padding-top: 20px;
        }
    }

    & + & {
        margin-top: 20px;

        .group-thread__column-name {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .group-thread__column-value {
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
}
