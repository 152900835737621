.image-block {
    width: 100%;
    position: relative;

    &:after {
        @include padding(75% null null null);
        content: '';
        display: block;
    }

    &__wrapper {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        > a {
            @include size(100%);
            display: block;
        }
    }

    &__image {
        @include size(100%);
        object-fit: cover;
        display: block;
    }

    &__content {
        @include padding(null 15px);
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    &__title,
    &__description {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1.3;
        position: relative;
        z-index: 3;
        color: $white;
    }

    &__title {
        font-family: $ff-tertiary;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__description {
        font-family: $ff-primary;
        font-weight: 400;
        line-height: 1.2;
    }

    &__link {
        @include position(absolute, 0 0 0 0);
        @include size(100%);
        z-index: 5;

        &:focus {
            outline: none;
        }
    }

    &--vertical {
        &:after {
            @include padding(133.33% null null null);
        }
    }

    &--square {
        &:after {
            @include padding(100% null null null);
        }
    }

    &--middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--overlay {
        &:before {
            @include position(absolute, 0 0 0 0);
            @include size(100%);
            display: block;
            content: '';
            z-index: 1;
            background-color: rgba($black, .5);
            transition: background-color $transition-duration $transition-timing-function;
        }

        &:hover {
            &:before {
                background-color: rgba($black, .55);
            }
        }
    }

    &--clear {
        &:before {
            background-color: transparent;
        }
    }
}
