.slider-6 {
    &__pages {
        @include position(absolute, -28px 0 null null);
        font-family: $ff-secondary;
        font-size: 12px;
        font-weight: 700;
        color: $gray-8;
        text-transform: uppercase;
    }

    &__image {
        width: 100%;
    }

    &__current-page {
        color: $black;

        &:after {
            @include margin(0 5px);
            line-height: 1;
            font-size: 0.95em;
            content: '/';
        }
    }

    .slider-arrow {
        top: 94.29px;

        @include media-breakpoint-up(sm) {
            top: 148.955px;
        }

        @include media-breakpoint-up(md) {
            top: 208.585px;
        }

        @include media-breakpoint-up(lg) {
            top: 142.33px;
        }

        @include media-breakpoint-up(xl) {
            top: 192.025px;
        }

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }
}
