.slider-3 {
    @include margin(25px null);

    &__item {
        @include padding(null 7.5px);
    }

    .slick-list {
        @include margin(null -7.5px);
    }

    .slider-arrow {
        top: 40px;

        @include media-breakpoint-up(sm) {
            top: 43px;
        }

        @include media-breakpoint-up(md) {
            top: 65px;
        }

        @include media-breakpoint-up(lg) {
            top: 40px;
        }

        @include media-breakpoint-up(xl) {
            top: 59px;
        }

        @include media-breakpoint-down(xs) {
            &--left {
                left: -14px;
            }

            &--right {
                right: -14px;
            }
        }
    }
}
