.ovulation-calendar {
    .ui-datepicker-inline {
        @include padding(5px);
        box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);
        font-family: $ff-secondary;
        font-size: 14px;
    }

    .ui-datepicker-header {
        position: relative;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        @extend .fas;
        @include size(20px);
        font-size: 0;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 32px;

        .ui-icon {
            color: $white;
            position: relative;
            z-index: 111;

            &:before {
                font-size: 16px;
            }
        }
    }

    .ui-datepicker-prev {
        @include position(absolute, 0 null null 0);

        .ui-icon {
            &:before {
                content: "\f0d9";
            }
        }
    }

    .ui-datepicker-next {
        @include position(absolute, 0 0 null null);

        .ui-icon {
            &:before {
                content: "\f0da";
            }
        }
    }

    .ui-datepicker-title {
        @include margin(3px);
        @include padding(6px 30px 5px);
        height: 32px;
        font-weight: 600;
        text-align: center;
        background-color: $secondary;
        border-radius: 5px;
        color: $white;
    }

    .ui-datepicker-calendar {
        width: 100%;
        text-align: center;

        &,
        thead,
        tbody {
            display: block;
        }

        tr {
            display: flex;

        }

        th,
        td {
            @include margin(3px);
            @include padding(5px 0);
            display: block;
            flex: 1;
            border-radius: 5px;
            box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
        }

        th {
            font-weight: 400;
            background-color: $primary;
            color: $white;
        }

        td {
            &.not_ovulation_day {
                background-color: #c2e5be;
            }

            &.ovulation_day {
                background-color: #64c877;
            }

            &.not_ovulation_day,
            &.ovulation_day {
                a {
                    color: initial;
                }
            }
        }
    }
}
