.group-details-block {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    &__head {
        display: flex;
        flex-direction: column;
    }

    &__search-block {
        margin-top: 30px;

        @include media-breakpoint-up(lg) {
            @include margin(-47px null null);
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__form {
        @include media-breakpoint-up(xs) {
            display: flex;
            flex-direction: row;
        }

        .form-control {
            width: 100%;

            @include media-breakpoint-up(xs) {
                flex: 1;
                margin-right: -2px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .btn {
            margin-top: 10px;
            width: 100%;
            min-width: 0;

            @include media-breakpoint-up(xs) {
                width: 100px;
                margin-top: 0;
            }
        }

        &--top-right {
            @include media-breakpoint-up(lg) {
                order: 1;
                z-index: 1;
                width: 260px;
                position: relative;
                top: -5px;
                left: 47px;
            }

            @include media-breakpoint-up(xl) {
                width: 310px;
                left: 0;
            }
        }
    }

    &__buttons {
        margin-top: 10px;
        text-align: center;

        @include media-breakpoint-up(xs) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 15px;
            order: 2;
        }

        .btn {
            margin-top: 5px;
            width: 70%;
            min-width: 0;

            @include media-breakpoint-up(xs) {
                max-width: 200px;
                flex: 1;
                width: auto;
                margin-top: 0;
            }
        }
    }

    &__list {
        margin-top: 30px;
    }

    .heading-bg {
        @include media-breakpoint-up(lg) {
            margin-top: 40px;
            order: 3;
        }
    }

    &--search {
        .heading-bg {
            @include media-breakpoint-down(xxs) {
                font-size: 26px;
            }

            @include media-breakpoint-up(xs) {
                &--secondary {
                    &,
                    &:before {
                        width: 340px;
                    }
                }
            }
        }
    }
}
