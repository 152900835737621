.related-recipes {
    @include margin(20px null null);
    @include padding(20px 20px 10px);
    background-color: $light-red2;
    position: relative;

    &__list {
        margin-top: 15px;
        font-family: $ff-secondary;
        font-weight: 600;
        font-size: 16px;
    }

    &__item {
        margin-top: 5px;
    }

    &__detail {
        display: block;
        font-size: 12px;
        margin-top: -3px;
        color: $primary;
    }

    .heading-bg {
        @include position(absolute, null null calc(100% - 25px) 0);

        &__text {
            font-size: 30px;
        }
    }
}
