.recipes-media {
    margin-top: 20px;

    &__description {
        margin-top: 20px;
        font-family: $ff-tertiary;
        font-size: 12px;
    }

    &__sliders {
        position: relative;
    }

    &__slider {
        &.is-disabled {
            display: none;
        }
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__button {
        width: 130px;
        min-width: auto;

        @include media-breakpoint-up(sm) {
            width: 150px;
        }

        &:not(.is-active) {
            &:not(:hover) {
                opacity: .5;
            }
        }
    }

    .dots-block {
        margin-top: 5px;
    }

    .slider__arrows {
        top: 50%;
        transform: translate3d(0, -35px, 0);
    }

    .slider-arrow {
        &--left {
            left: -10px;

            @include media-breakpoint-up(sm) {
                left: -17px;
            }
        }

        &--right {
            right: -10px;

            @include media-breakpoint-up(sm) {
                right: -17px;
            }
        }
    }
}
