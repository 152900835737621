.banner {
    @include margin(20px auto);
    position: relative;
    text-align: center;

    @include media-breakpoint-down(xs) {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .page__aside & {
        @include margin(50px null);

        @include media-breakpoint-up(lg) {
            @include margin(30px null);
        }
    }

    .featured-content__container & {
        @include margin(0 auto);
    }

    &__media {
        @include margin(0 auto);
        max-width: 100%;
    }

	@include media-breakpoint-down(xs) {
		&__expand {
			@include margin(null -15px);
		}
	}

    &__link {
        &,
        &:hover {
            opacity: 1;
        }
    }
}
