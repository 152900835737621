.weekly-updates-block {
    @include margin(null auto);
    font-size: 14px;

    @include media-breakpoint-only(md) {
        max-width: 400px;
    }

    .form-group,
    .custom-checkbox {
        font-family: $ff-tertiary;
    }

    .input-group-text {
        border: 0;
    }
}
