.member-comment {
    font-family: $ff-tertiary;
    font-size: 14px;

    &__avatar {
        width: 103px;
    }

    &__buttons {
        @include margin(15px -7.5px null);
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }

        .btn {
            @include padding(null 30px);
            @include margin(null 7.5px);
            min-width: 0;
        }
    }

    & + & {
        margin-top: 40px;

        @include media-breakpoint-up(sm) {
            margin-top: 50px;
        }
    }

    .news {
        margin-top: 0;
        padding-top: 0;
    }

    .news__image-block {
        @include media-breakpoint-down(xs) {
            @include margin(null 0);
        }
    }

    .news__title {
        margin-top: 5px;
    }

    .news__link,
    .news__description {
        font-size: 12px;
    }

    .news__description {
        margin-bottom: 15px;
        white-space: nowrap;
    }
}
