.list-url {
    @include margin(20px null 0);
    padding-left: 0;
    list-style-type: none;
    font-family: $ff-tertiary;
    font-size: 13px;

    li {
        padding-left: 10px;
        position: relative;

        &:before {
            @include position(absolute, 8px null null 0);
            @include size(3px);
            background-color: $black;
            border-radius: 50%;
            content: '';
            display: block;
        }

        &:not(:first-child) {
            margin-top: 4px;
        }
    }

    a {
        &,
        &:hover {
            color: $body-color;
        }
    }

    .chat-block & {
        margin-top: 0;
        font-size: 12px;
        line-height: 20px;

        li {
            white-space: nowrap;
            margin-top: 0;
            padding-left: 0;

            &:before {
                display: none;
            }
        }
    }
}
