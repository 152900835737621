.search-result {
    @include padding(15px 20px);
    font-family: $ff-secondary;
    margin-top: 40px;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);

    @include media-breakpoint-down(xxs) {
        text-align: center;
    }

    &__title {
        @include margin(0 null);
        font-size: 14px;
        font-weight: 600;
    }

    &__description {
        margin-top: 3px;
        font-size: 12px;
    }

    .btn {
        width: auto;
        min-width: auto;
        margin-top: 10px;
    }
}
