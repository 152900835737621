.search {
    &__form {
        @include position(absolute, -4px 0 null 0);
        background-color: $white;
        width: 250px;
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: hidden;
        opacity: 0;
        z-index: 0;
        color: $black;

        &.is-visible {
            visibility: visible;
            opacity: 1;
            z-index: 1;

            + .search__open-button {
                visibility: hidden;
            }
        }
    }

    &__open-button {
        @include padding(null 5px);
    }

    .form-control {
        @include padding(null null null 40px);
        border: 0;
        font-family: $ff-secondary;
        font-size: 12px;
        color: $gray-7;
        box-shadow: none;
    }

    &__submit-button {
        @include position(absolute, 1px null null 0);
        color: $gray-7;
        font-size: 20px;
    }

    .social-list + & {
        @include margin(null null null 10px);
    }

    i {
        color: inherit;
    }
}
