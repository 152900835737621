.slider-10 {
    @include margin(0 -15px null -7.5px);

    @include media-breakpoint-up(sm) {
        @include padding(null 40px);
    }

    @include media-breakpoint-up(sm) {
        @include margin(null -7.5px);
    }

    &__item {
        @include padding(null 7.5px);
    }

    .slider__wrapper {
        overflow: hidden;
    }

    .slick-list {
        @include media-breakpoint-down(xs) {
            width: 85%;
            overflow: visible;
        }
    }

    .slider__arrows {
        top: 14px;
        left: 20px;
        right: 20px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .slick-arrow {
        @include media-breakpoint-up(sm) {
            top: 61px;
        }

        @include media-breakpoint-up(md) {
            top: 55.75px;
        }

        @include media-breakpoint-up(lg) {
            top: 71.875px;
        }

        @include media-breakpoint-up(xl) {
            top: 63px;
        }
    }

    .news {
        @include margin(0 null null null);

        &__image-block,
        &__media {
            @include media-breakpoint-down(xs) {
                @include margin(null 0);
            }
        }
    }

    .dots-block {
        @include media-breakpoint-up(sm) {
            display: none !important;
        }
    }
}
