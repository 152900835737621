.article-content {
    @include clearfix;
    font-family: $ff-tertiary;
    font-size: $font-size-base;

    &__title {
        font-family: $ff-secondary;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__inline-image {
        @include margin(null auto 20px);
        @include padding(null 10px);
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            border: 2px solid $secondary;
            box-sizing: content-box;

            & + .btn {
                @include margin(10px -2px null);
            }
        }

        @include media-breakpoint-up(sm) {
            @include margin(null 30px 20px null);
            max-width: 250px;
            float: left;
        }

        @include media-breakpoint-up(xl) {
            @include margin(null 40px 20px null);
            max-width: 250px;
            float: left;
        }
    }

    &--md {
        font-size: 14px;
        line-height: 1.7;
    }

    &--sm {
        font-size: 13px;
        line-height: 1.5;
    }

    > img {
        @include margin(20px 0);
        display: block;
    }

    img {
        @include article-content-img;
    }

    &.article-content--clear-nbsp {
        p {
            @include margin(0 null);

            h3 + & {
                @include margin(5px null null null);
            }
        }

        > img {
            @include margin(0 null);
        }
    }

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
