.my-account {
    @include padding(15px 10px);
    border-radius: 3px;
    margin-top: 20px;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .3);

    &__container {
        & + & {
            margin-top: 30px;
        }
    }

    &__row {
        &:not(:first-child) {
            margin-top: 15px;
        }

        &:first-child {
            margin-bottom: 30px;

            .my-account__right {
                text-align: center;

                @include media-breakpoint-up(sm) {
                    padding-bottom: 20px;
                    display: flex;
                    justify-content: flex-end;
                    text-align: left;
                    flex-direction: column;
                }
            }
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            flex-direction: row;
        }
    }

    &__left {
        @include media-breakpoint-up(sm) {
            flex: 0 0 30%;
        }
    }

    &__right {
        @include media-breakpoint-down(xs) {
            margin-top: 5px;
        }

        @include media-breakpoint-up(sm) {
            flex: 0 0 70%;
            padding-left: 20px;
        }
    }

    &__label,
    &__value {
        font-family: $ff-secondary;
        font-size: 12px;
        display: block;
        text-transform: uppercase;
    }

    &__label {
        font-weight: 700;

        @include media-breakpoint-down(xs) {
            padding-left: 15px;
        }

        @include media-breakpoint-up(sm) {
            padding-top: 5px;
        }

        &:after {
            content: ':';
        }
    }

    &__value {
        @include padding(5px 15px);
        border-radius: 3px;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, .3);
    }

    .avatar {
        max-width: 120px;
        padding-bottom: 120px;

        @include media-breakpoint-down(xs) {
            @include margin(null auto);
        }
    }
}
