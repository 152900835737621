.search-filter {
    @include padding(15px 20px);
    margin-top: 40px;
    font-size: 14px;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);

    &__description {
        font-family: $ff-secondary;
        font-weight: 600;

        a {
            @include margin(null 5px);
            color: $primary;
        }
    }

    &__fields {
        @include margin(null -5px);
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @include media-breakpoint-up(xs) {
            flex-direction: row;
        }

        @include media-breakpoint-only(md) {
            @include margin(null -10px);
        }

        @include media-breakpoint-up(xl) {
            @include margin(null -10px);
        }
    }

    .form-group {
        @include margin(null 5px);

        @include media-breakpoint-up(xs) {
            flex: 1;
        }

        @include media-breakpoint-only(md) {
            @include margin(null 10px);
        }

        @include media-breakpoint-up(xl) {
            @include margin(null 10px);
        }

        &:last-child {
            @include media-breakpoint-up(xs) {
                flex: 0 0 120px;
            }
        }

        .btn {
            width: 100%;
            min-width: auto;
        }
    }
}
