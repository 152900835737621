.recipes-filter {
    &__form {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        margin-bottom: 40px;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-only(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        &:last-child {
            @include media-breakpoint-up(sm) {
                display: flex;
                flex-direction: row;
                grid-column: 1 / 3;
            }

            @include media-breakpoint-up(md) {
                grid-column: 1 / 4;
            }

            @include media-breakpoint-only(lg) {
                grid-column: 1 / 3;
            }

            .form-control {
                @include media-breakpoint-up(sm) {
                    margin-right: -5px;
                }
            }

            .recipes-filter__button {
                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }

    &__button-group {
        display: flex;
        flex-direction: row;
        margin-left: -5px;
        margin-bottom: 30px;

        .btn {
            width: 50%;
            margin-left: 5px;
            min-width: 0;
        }

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__button {
        margin-top: 15px;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    &__back {
        min-width: 0;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__chef-photo {
        max-width: 150px;

        @include media-breakpoint-down(xs) {
            @include margin(25px auto null);
        }

        @include media-breakpoint-up(sm) {
            margin-left: 50px;
        }

        img {
            max-width: 100%;
        }
    }

    .heading-bg {
        @include media-breakpoint-up(sm) {
            flex: 1;
        }
    }
}

.is-recipes-filter {
    @include media-breakpoint-down(xs) {
        .recipes-filter__form {
            @include padding(70px 40px 30px);
            @include position(fixed, 0 0 0 0);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            background-color: $white;
            display: block;
            z-index: 99;
        }

        .recipes-filter__items {
            margin-bottom: 0;

            @media (orientation: landscape) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .recipes-filter__item {
            &:last-child {
                @media (orientation: landscape) {
                    grid-column: 1 / 3;
                    display: flex;
                    flex-direction: row;

                    .form-control {
                        margin-right: -5px;
                    }

                    .recipes-filter__button {
                        margin-top: 0;
                        width: auto;
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }

        .recipes-filter__back {
            @include position(absolute, 15px null null 5px);
        }

        &,
        body {
            overflow: hidden;
        }

        .header {
            z-index: -1;
        }
    }
}
