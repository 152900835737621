@mixin heading-bg-image($url, $w, $h, $isFixedWidth, $isFixedHeight: true) {
    @if ($isFixedWidth) {
        width: $w;
    }

    @if ($isFixedWidth and $isFixedHeight) {
        height: $h;
    }

    &:before {
        @include size($w, $h);
        background-image: url('#{$images}heading-bg/#{$url}');
    }
}

@mixin swipe-row {
    margin-bottom: -10px;
    padding-bottom: 10px;
    min-width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin grid-row($size) {
    @include margin(null (-$size));

    > .col,
    > [class*='col-'] {
        @include padding(null $size);
    }
}

@mixin pagination-color($color: $primary, $bgc: $light-red) {
    &.pagination--bg {
        background-color: $bgc;
    }

    .page-item__nav {
        border: 1px solid $color;
        background-color: $color;
    }

    .page-link {
        color: $color;

        &.is-current {
            color: $color;
        }
    }
}

@mixin article-content-img {
    max-width: 100%;
    height: auto !important;

    @include media-breakpoint-down(xs) {
        max-width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        filter: blur(0);
        image-rendering: crisp-edges;
        float: none !important;
        display: block;
    }
}

@mixin label-style {
    @include margin(null null 5px null);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 600;
    font-family: $ff-tertiary;
}

@mixin placeholder-color($color: $body-color) {
    &::placeholder {
        color: $color;
        opacity: 1;
        line-height: normal;
    }
}
